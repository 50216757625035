import { fromNullable, none, some, Option } from 'fp-ts/lib/Option';
import { mapOption } from 'fp-ts/lib/Array';

import { query, queryK } from 'sdi/shape';
import {
    measureQueryFactory,
    withInteraction,
    InteractionPosition,
    InteractionExtract,
} from 'sdi/map';
import tr from 'sdi/locale';

import {
    TableSource,
    TableDataType,
    TableDataRow,
    tableQueries,
} from 'sdi/components/table';
import { ILayerInfo, IMapInfo } from 'sdi/source';
import { bookmarkLayerID } from './bookmark';
import { getAllMaps } from './app';
import { getRootUrl } from 'sdi/app';

export const HarvestLayerName = '__harvest_layer__';
export const HarvestMetadataID = '__bookmarks_meta__';

export const getView = queryK('port/map/view');
export const getMapUrlFromView = (mapId: string) => {
    const view = getView();
    if (view.center.length == 2) {
        const lat = view.center[0];
        const lon = view.center[1];
        return `${getRootUrl('view')}/${mapId}/${lat}/${lon}/${view.zoom}`;
    }
    return `${getRootUrl('view')}/${mapId}/`;
};

// export const getAllBaseLayers = queryK('port/map/baseLayers');

export const getScaleLine = queryK('port/map/scale');

export const getLoading = queryK('port/map/loading');

export const getMapExtent = () => fromNullable(getView().extent);

// export const getBaseLayer = () =>
//     getMapInfoOption()
//         .map(i => i.baseLayer)
//         .toNullable();

export const getInteraction = queryK('port/map/interaction');
export const measureQueries = measureQueryFactory(getInteraction);

export const getInteractionMode = () => getInteraction().label;

export const getPointerPosition = () => {
    let ret: Option<InteractionPosition> = none;
    withInteraction<InteractionPosition>(
        'position',
        i => (ret = some(i))
    )(getInteraction());
    return ret;
};

const getExtractKeys = () => [tr.view('identifier'), tr.view('layerId')];

const getExtractTypes = (): TableDataType[] => ['string', 'string'];

export const withExtract = () => {
    let ret: Option<InteractionExtract> = none;
    withInteraction<InteractionExtract>(
        'extract',
        i => (ret = some(i))
    )(getInteraction());
    return ret;
};

const getExtractData = (): TableDataRow[] =>
    withExtract().fold([], ({ state }) =>
        state.map(e => ({
            from: e.featureId.toString(),
            cells: [e.featureId.toString(), e.layerId],
        }))
    );

const getExtractSource = (): TableSource => ({
    kind: 'local',
    data: getExtractData(),
    keys: getExtractKeys(),
    types: getExtractTypes(),
});

export const extractTableQueries = tableQueries(
    queryK('component/table/extract'),
    getExtractSource
);

export const getPrintRequest = queryK('port/map/printRequest');
export const getPrintResponse = queryK('port/map/printResponse');

export type LinkDirection = 'forward' | 'backward';

const formatLink = (maps: Readonly<IMapInfo[]>) => (mid: string) =>
    fromNullable(maps.find(m => m.id === mid));

const getLinkedMapIds = (mid: string, ld: LinkDirection): Option<string[]> => {
    const links = query('data/links');

    if (mid === null || !(mid in links)) {
        return none;
    }
    return some(
        links[mid]
            .filter(
                link => mid === (ld === 'forward' ? link.source : link.target)
            )
            .map(link => (ld === 'forward' ? link.target : link.source))
    );
};

export const getAllLinkedMapsIds = (mid: string) => {
    const mids_back = fromNullable(mid)
        .chain(id => getLinkedMapIds(id, 'backward'))
        .getOrElse([]);
    const mids_for = fromNullable(mid)
        .chain(id => getLinkedMapIds(id, 'forward'))
        .getOrElse([]);
    return mids_back.concat(mids_for);
};

export const getLinks = (ld: LinkDirection) => {
    const mid = query('app/current-map');

    const maps = getAllMaps();
    const fm = formatLink(maps);
    const mids = fromNullable(mid)
        .chain(id => getLinkedMapIds(id, ld))
        .getOrElse([]);

    return some(mapOption(mids, fm));
};

export const withoutBookmarksOrHarvest = (ls: ILayerInfo[]) =>
    ls.filter(l => l.id !== bookmarkLayerID && l.id !== HarvestLayerName);
