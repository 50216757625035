/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as io from 'io-ts';
import { fromNullable, Option, some } from 'fp-ts/lib/Option';
import { index } from 'fp-ts/lib/Array';
import { getFeaturePropOption } from '../../util';
import { fromRecord } from '../../locale';
import { i, a, p, l, u, MessageRecordIO, TypeOf, nullable } from './io';
import { StyleConfigIO } from './style';
import { RowConfigIO } from './row-config';
import { uuidIO } from './uuid';
import { scopeOption } from '../../lib';
import { Feature } from './geojson';

export const FeatureViewDefaultIO = i(
    {
        type: l('default'),
    },
    'FeatureViewDefaultIO'
);
export type FeatureViewDefault = TypeOf<typeof FeatureViewDefaultIO>;

export const FeatureViewConfigIO = io.intersection(
    [
        i({
            type: l('config'),
            rows: a(RowConfigIO),
        }),
        p({
            mainTitleProperty: MessageRecordIO,
            withoutZeros: io.boolean,
        }),
    ],
    'FeatureViewConfigIO'
);
export type FeatureViewConfig = TypeOf<typeof FeatureViewConfigIO>;

export const FeatureViewOptionsIO = u(
    [FeatureViewDefaultIO, FeatureViewConfigIO],
    'FeatureViewOptionsIO'
);
export type FeatureViewOptions = TypeOf<typeof FeatureViewOptionsIO>;

export const getFeatureTitle = (
    feature: Feature,
    config: FeatureViewOptions
): Option<string> => {
    switch (config.type) {
        case 'default':
            return some(feature.id.toString());
        case 'config':
            return fromNullable(config.mainTitleProperty).chain(prop =>
                getFeaturePropOption(feature, fromRecord(prop))
            );
    }
};

export const LayerGroupIO = i(
    {
        id: io.string,
        name: MessageRecordIO,
    },
    'LayerGroupIO'
);
export type LayerGroup = TypeOf<typeof LayerGroupIO>;

export const ILayerInfoIO = io.intersection(
    [
        i({
            id: io.string,
            metadataId: io.string,
            visible: io.boolean,
            style: StyleConfigIO,
            featureViewOptions: FeatureViewOptionsIO,
            legend: nullable(MessageRecordIO),
            group: nullable(LayerGroupIO),
            visibleLegend: io.boolean,
            opacitySelector: io.boolean,
        }),
        p({
            switchVisibility: io.boolean,
            exportable: io.boolean,
            minZoom: io.number,
            maxZoom: io.number,
        }),
    ],
    'ILayerInfoIO'
);
export type ILayerInfo = TypeOf<typeof ILayerInfoIO>;

export const IMapBaseLayerIO = i(
    {
        id: io.string,
        codename: io.string,
        name: MessageRecordIO,
        srs: io.string,
        params: i({
            LAYERS: MessageRecordIO,
            VERSION: io.string,
        }),
        recommended: io.boolean,
        url: io.string,
    },
    'IMapBaseLayerIO'
);
export type IMapBaseLayer = TypeOf<typeof IMapBaseLayerIO>;

export const IServiceBaseLayersIO = io.array(
    i({
        id: io.string,
        layers: io.array(IMapBaseLayerIO),
    })
);
export type IServiceBaseLayers = TypeOf<typeof IServiceBaseLayersIO>;

export const IBaseLayerGroupIO = i({
    id: io.string,
    name: MessageRecordIO,
    layers: io.array(io.string),
    recommended: io.boolean,
});
export type IBaseLayerGroup = TypeOf<typeof IBaseLayerGroupIO>;

export const IBaseLayerGroupsIO = io.array(IBaseLayerGroupIO);
export type IBaseLayerGroups = TypeOf<typeof IBaseLayerGroupsIO>;

export interface MapBaseLayerIdent {
    service: string;
    layer: string;
}

export const tryMapBaseLayerIdent = (
    fullName: string
): Option<MapBaseLayerIdent> =>
    scopeOption()
        .let('parts', some(fullName.split('/')))
        .let('service', ({ parts }) => index(0, parts))
        .let('layer', ({ parts }) => index(1, parts))
        .map(({ service, layer }) => ({ service, layer }));

export const findMapBaseLayer = (
    services: IServiceBaseLayers | Readonly<IServiceBaseLayers>,
    fullName: string
) =>
    tryMapBaseLayerIdent(fullName).chain(({ service, layer }) =>
        fromNullable(services.find(s => s.id === service)).chain(s =>
            fromNullable(s.layers.find(l => l.codename === layer))
        )
    );

export const MapStatusIO = u(
    [l('draft'), l('published'), l('saved')],
    'MapStatusIO'
);
export type MapStatus = TypeOf<typeof MapStatusIO>;

export const ExtentIO = io.tuple(
    [io.number, io.number, io.number, io.number],
    'ExtentIO'
);
export type Extent = TypeOf<typeof ExtentIO>;

export const IMapBaselayerOrGroupIO = i({
    tag: io.union([io.literal('group'), io.literal('layer')]),
    id: io.string,
    // codename: io.string,
});
export type IMapBaselayerOrGroup = TypeOf<typeof IMapBaselayerOrGroupIO>;

export const IMapInfoIO = io.intersection(
    [
        i({
            id: io.string,
            id_origin: io.string,
            title: MessageRecordIO,
            url: io.string,
            status: MapStatusIO,
            lastModified: io.number,
            description: MessageRecordIO,
            attachments: a(uuidIO),
            layers: a(io.string),
            baseLayer: io.union([io.string, io.array(io.string)]), // "{service-id}/{layer-name}"
            categories: a(io.string),
        }),
        p({
            baseLayersSelection: io.array(IMapBaselayerOrGroupIO),
            inPublicGroup: io.boolean,
            user: io.number,
            imageUrl: io.string,
            extent: ExtentIO,
        }),
    ],
    'IMapInfoIO'
);
export type IMapInfo = TypeOf<typeof IMapInfoIO>;

export const getMapBaseLayerSelection = (umap: IMapInfo) =>
    fromNullable(umap.baseLayersSelection).getOrElse([]);
