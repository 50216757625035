/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import { identity } from 'fp-ts/lib/function';
import { Extent } from 'ol/extent';

import { dispatch, observe, dispatchK, assign, assignK } from 'sdi/shape';
import {
    IMapInfo,
    MessageRecord,
    ILayerInfo,
    getMessageRecord,
    fetchIO,
    StreamingMetaIO,
    Inspire,
    FieldsDescriptorList,
    remoteLoading,
    remoteSuccess,
    remoteError,
    mapRemote,
    remoteToOption,
} from 'sdi/source';
import { removeLayerAll, addFeaturesToLayer, addLayer } from 'sdi/map';
import { getApiUrl, getRootUrl, getUserId, setCollapsible } from 'sdi/app';
import { iife, scopeOption } from 'sdi/lib';
import { activity, clickAction } from 'sdi/activity';
import {
    addStream,
    clearStreams,
    mapStream,
    pushStreamExtent,
} from 'sdi/geodata-stream';

import { AppLayout } from '../shape/types';
import {
    fetchLayer,
    fetchAlias,
    fetchAllMaps,
    fetchCategories,
    fetchDatasetMetadata,
    fetchMap,
    fetchAttachment,
    fetchBaseLayerAll,
    fetchLinks,
    fetchUsers,
    fetchBaseLayerGroups,
    fetchLayers,
    fetchBaseLayerSelection,
} from '../remote';
import {
    getSyntheticLayerInfo,
    getLayerData,
    mapReady,
    getMapInfo,
    getCurrentMap,
    getLayerDataFromInfo,
    getDatasetMetadataOption,
    getMapOption,
    getCurrentLayerOpt,
    getBaseLayersFromGroup,
    getMapLayers,
    getAllMaps,
} from '../queries/app';
import { addBookmarksToMap } from './bookmark';
import { mapName } from '../components/map';
import { getAllLinkedMapsIds, getMapExtent, getView } from '../queries/map';
import { ensureArray, isNotNullNorUndefined, once } from 'sdi/util';
import { updateLoadingAddOne, updateLoadingRemoveOne, viewEvents } from './map';
import { navigateMapAction } from 'sdi/activity';
import { withoutBookmarks } from '../queries/bookmark';
import { addHarvestToMap } from './harvest';
import { getMapList, getMaps } from '../queries/mapnavigator';

import { clearSelectedRow } from './table';

const logger = debug('sdi:events/app');
export const activityLogger = activity('view');

// lets keep it around for next time we have an issue
// that doesn't show when dev tools are opened
// const notice = (() => {
//     const box = document.createElement('div');
//     box.id = 'notice';
//     box.setAttribute(
//         'style',
//         'position:absolute;z-index:999999;width:500px;height:500px;border:1px solid green;right:0;bottom:30px;overflow-y: auto;font-family: monospace; font-size: 10px;'
//     );
//     const attach = () => {
//         if (document.body) {
//             document.body.appendChild(box);
//         } else {
//             window.setTimeout(attach, 300);
//         }
//     };
//     attach();

//     return (msg: string) => {
//         const e = document.createElement('div');
//         e.setAttribute('style', 'border-bottom:1px solid grey;padding:4px');
//         e.appendChild(document.createTextNode(msg));
//         box.appendChild(e);
//     };
// })();

observe('data/maps', () =>
    fromNullable(getMapInfo()).map(info => {
        info.attachments.forEach(aid =>
            fetchAttachment(getApiUrl(`attachments/${aid}`)).then(a =>
                attachments(s => s.concat([a]))
            )
        );
    })
);

observe('port/map/view', view => {
    fromNullable(view.extent).map(e => {
        mapStream(e, ({ uri, lid }, extent) => {
            loadLayerDataExtent(lid, uri, extent);
        });
    });
});

const layerInRange = (info: ILayerInfo) => {
    const low = fromNullable(info.minZoom).getOrElse(0);
    const high = fromNullable(info.maxZoom).getOrElse(30);
    const zoom = getView().zoom;
    return info.visible && zoom > low && zoom < high;
};

const whenInRange = fromPredicate(layerInRange);

export const loadUsers = () =>
    fetchUsers(getApiUrl('users')).then(assignK('data/users'));

const { markVisibilityPending, delVisibilityPending, isVisibilityPending } =
    iife(() => {
        const register: Set<string> = new Set();

        const hash = (a: string, b: string) => `${a}/${b}`;
        const markVisibilityPending = (mid: string, lid: string) =>
            register.add(hash(mid, lid));

        const delVisibilityPending = (mid: string, lid: string) =>
            register.delete(hash(mid, lid));

        const isVisibilityPending = (mid: string, lid: string) =>
            register.has(hash(mid, lid));

        return {
            markVisibilityPending,
            delVisibilityPending,
            isVisibilityPending,
        };
    });

const prepareStreamedLayer = (
    mapInfo: IMapInfo,
    layerInfo: ILayerInfo,
    md: Inspire,
    fields: FieldsDescriptorList
) => {
    dispatch('data/layers', state => {
        if (!(md.uniqueResourceIdentifier in state)) {
            state[md.uniqueResourceIdentifier] = {
                type: 'FeatureCollection',
                fields,
                features: [],
            };
        }
        return state;
    });
    if (layerInfo.visible && layerInRange(layerInfo)) {
        fromNullable(getView().extent).map(e => {
            loadLayerDataExtent(layerInfo.id, md.uniqueResourceIdentifier, e);
        });
    } else {
        markVisibilityPending(mapInfo.id, layerInfo.id);
    }
};

const loadLayer = (mapInfo: IMapInfo) => (layerInfo: ILayerInfo) =>
    fetchDatasetMetadata(getApiUrl(`metadatas/${layerInfo.metadataId}`))
        .then(md => {
            dispatch('data/datasetMetadata', state => {
                state[md.id] = md;
                return state;
            });

            addLayer(
                mapName,
                () => getSyntheticLayerInfo(layerInfo.id),
                () => getLayerData(md.uniqueResourceIdentifier)
            );

            if (isNotNullNorUndefined(md.dataStreamUrl)) {
                addStream({
                    uri: md.uniqueResourceIdentifier,
                    lid: layerInfo.id,
                });
                return fetchIO(StreamingMetaIO, md.dataStreamUrl)
                    .then(({ fields }) => {
                        prepareStreamedLayer(mapInfo, layerInfo, md, fields);
                    })
                    .catch(() => {
                        logger(
                            `[ERROR] failed to get fields for ${md.uniqueResourceIdentifier}`
                        );
                        prepareStreamedLayer(mapInfo, layerInfo, md, []);
                    });
            } else {
                return loadLayerData(md.uniqueResourceIdentifier);
            }
        })
        .catch(err =>
            logger(`Failed to load MD ${layerInfo.metadataId}: ${err}`)
        );

const loadLayerDataExtent = (layerId: string, url: string, bbox: Extent) =>
    getSyntheticLayerInfo(layerId).map(({ info }) => {
        whenInRange(info).map(info => {
            const title = getDatasetMetadataOption(info.metadataId).map(md =>
                getMessageRecord(md.resourceTitle)
            );
            pushStreamExtent(bbox, { lid: layerId, uri: url });
            title.map(updateLoadingAddOne);
            fetchLayer(
                `${url}?bbox=${bbox[0]},${bbox[1]},${bbox[2]},${bbox[3]}`
            )
                .then(layer => {
                    if (layer.features !== null) {
                        logger(
                            'addFeaturesToLayer',
                            title.getOrElse({ fr: info.metadataId }).fr,
                            layer.features.length
                        );

                        addFeaturesToLayer(mapName, info, layer.features);
                        dispatch('data/layers', state => {
                            if (url in state) {
                                state[url].features = state[
                                    url
                                ].features.concat(layer.features);
                            } else {
                                state[url] = layer;
                            }
                            return state;
                        });
                    }
                    title.map(updateLoadingRemoveOne);
                })
                .catch(err => {
                    title.map(updateLoadingRemoveOne);
                    logger(`Failed to load features at ${url} due to ${err}`);
                    dispatch('remote/errors', state => ({
                        ...state,
                        [url]: `${err}`,
                    }));
                });
        });
    });

const loadLayerData = (url: string) => {
    logger(`loadLayerData(${url})`);
    return fetchLayer(url)
        .then(layer => {
            dispatch('data/layers', state => {
                logger(`Put layer ${url} on state`);
                state[url] = layer;
                return state;
            });
        })
        .catch(err => {
            logger(`Failed to load layer at ${url} due to ${err}`);
            dispatch('remote/errors', state => ({ ...state, [url]: `${err}` }));
        });
};

const loadMapFromInfo = (info: IMapInfo, delay?: number) => {
    const mapIsReady = mapReady();
    logger(`loadMap ${info.id} ${mapIsReady} ${delay}`);
    if (info.status === 'published') {
        activityLogger(navigateMapAction(info.id, info.title));
    }
    if (mapIsReady) {
        clearSelectedRow();
        clearSelectedFeatures();
        removeLayerAll(mapName);
        const load = loadLayer(info);
        loadMapLayerInfo(info.id).then(() =>
            withoutBookmarks(getMapLayers(info)).map(load)
        );
        addBookmarksToMap();
        addHarvestToMap();
        fromNullable(info.extent).map(extent =>
            viewEvents.updateMapView({
                dirty: 'geo/extent',
                extent,
            })
        );
    } else {
        const d = delay !== undefined ? 2 * delay : 2;
        setTimeout(() => loadMapFromInfo(info, d), d);
    }
};

const findMap = (mid: string) => getMapOption(mid);

const attachments = dispatchK('data/attachments');

const loadLinks = (mid: string) => {
    if (getCurrentMap() === mid) {
        fetchLinks(getApiUrl(`map/links?mid=${mid}`))
            .then(links => {
                dispatch('data/links', data => ({ ...data, [mid]: links }));
            })
            .then(() => {
                const mids = getAllLinkedMapsIds(mid);
                const currentIds = getAllMaps().map(m => m.id);
                mids.filter(id => currentIds.indexOf(id) < 0).map(id =>
                    loadMapFromId(id)
                );
            });
    }
};

export const setLayout = (l: AppLayout) => {
    logger(`setLayout ${l}`);
    // dispatch('app/layout', state => state.concat([l]));
    assign('app/layout', l);
};

export const signalReadyMap = () => dispatch('app/map-ready', () => true);

export const loadMap = (infoManip = identity as (i: IMapInfo) => IMapInfo) =>
    fromNullable(getCurrentMap()).map(mid =>
        findMap(mid).foldL(
            () => {
                assign('data/maps', remoteLoading);
                fetchMap(getApiUrl(`maps/${mid}`))
                    .then(info => {
                        dispatch('data/maps', mapsRemote => {
                            return remoteToOption(mapsRemote).foldL(
                                () => remoteSuccess([info]),
                                data => remoteSuccess(data.concat([info]))
                            );
                        });
                        loadMapFromInfo(infoManip(info));
                        loadBaseLayerSelection(
                            getApiUrl(`baselayers_selection/${info.id}`)
                        );
                        // loadMapLayerInfo(info.id);
                    })
                    .then(() => loadLinks(mid))
                    .catch(response => {
                        dispatch('data/maps', maps =>
                            maps.tag === 'none' ? remoteError(response) : maps
                        );
                        const user = getUserId();

                        if (response.status === 403 && user.isNone()) {
                            window.location.assign(
                                getRootUrl(`login/view/${mid}`)
                            );
                            return;
                        }
                    });
            },
            info => {
                loadLinks(mid);
                loadMapFromInfo(infoManip(info));
                // loadMapLayerInfo(mid);
                // loadMapFromInfo(info);
                loadBaseLayerSelection(
                    getApiUrl(`baselayers_selection/${info.id}`)
                );
            }
        )
    );

// export const loadBaseLayer = (id: string, url: string) => {
//     fetchBaseLayer(url).then(bl => {
//         dispatch('data/baselayers', state => ({ ...state, [id]: bl }));
//     });
// };

export const loadMapFromId = (mid: string) =>
    fetchMap(getApiUrl(`maps/${mid}`)).then(info => {
        dispatch('data/maps', mapsRemote => {
            return remoteToOption(mapsRemote).foldL(
                () => remoteSuccess([info]),
                data => remoteSuccess(data.concat([info]))
            );
        });
    });

export const loadAllBaseLayers = (url: string) => {
    fetchBaseLayerAll(url).then(assignK('data/baselayers'));
};
export const loadBaseLayerSelection = (url: string) =>
    fetchBaseLayerSelection(url).then(baselayers => {
        updateCurrentMapInPlace(m => (m.baseLayersSelection = baselayers));
    });

export const loadBaseLayersGroups = (url: string) => {
    fetchBaseLayerGroups(url).then(assignK('data/baselayergroups'));
};

export const loadAllMaps = once(() => {
    assign('data/maps', remoteLoading);
    return fetchAllMaps(getApiUrl(`maps`))
        .then(m => {
            assign('data/maps', remoteSuccess(m));
            setDefaultCollapsible();
        })
        .catch(err => assign('data/maps', remoteError(err)));
    // fetchAllMaps(getApiUrl(`maps`)).then(assignK('data/maps'));
});

export const setLayers = (newLayers: ILayerInfo[]) =>
    dispatch('data/layerinfo-list', layerList =>
        layerList
            .filter(layer => newLayers.findIndex(nl => nl.id === layer.id) < 0)
            .concat(newLayers)
    );

export const addLayerData = (layer: ILayerInfo) =>
    dispatch('data/layerinfo-list', layerList =>
        layerList.filter(l => l.id != layer.id).concat(layer)
    );

export const loadMapLayerInfo = (mapId: string) => {
    const url = `${getApiUrl(`layerinfos?mid=`)}${mapId}`;
    return fetchLayers(url).then(infos => {
        setLayers(infos);
        infos.map(layer => {
            const md = getDatasetMetadataOption(layer.metadataId);
            if (md.isNone()) {
                loadMetadata(layer.metadataId);
            }
        });
    });
};

export const loadAlias = (url: string) => {
    fetchAlias(url).then(assignK('data/alias'));
};

export const loadCategories = (url: string) => {
    fetchCategories(url).then(assignK('data/categories'));
};

export const updateCurrentMapInPlace = (f: (m: IMapInfo) => void) =>
    fromNullable(getCurrentMap()).map(mid =>
        dispatch('data/maps', mapsRemote =>
            mapRemote(mapsRemote, maps => {
                fromNullable(maps.find(m => m.id === mid)).map(f);
                return maps;
            })
        )
    );

export const setLayerVisibility = (id: string, visible: boolean) =>
    updateCurrentMapInPlace(mapInfo => {
        getMapLayers(mapInfo).forEach(l => {
            if (l.id === id) {
                l.visible = visible;
                if (isVisibilityPending(mapInfo.id, l.id)) {
                    getMapExtent().map(e =>
                        mapStream(e, ({ uri, lid }, extent) =>
                            loadLayerDataExtent(lid, uri, extent)
                        )
                    );
                    delVisibilityPending(mapInfo.id, l.id);
                } else if (!visible) {
                    markVisibilityPending(mapInfo.id, l.id);
                }
            }
        });
    });

export const saveStyle = (layerInfo: ILayerInfo) => {
    const mid = getCurrentMap();
    dispatch('data/maps', mapsRemote =>
        mapRemote(mapsRemote, maps => {
            const idx = maps.findIndex(m => m.id === mid);
            if (idx !== -1) {
                const m = maps[idx];
                const layerIndex = getMapLayers(m).findIndex(
                    l => l.id === layerInfo.metadataId
                );
                if (layerIndex >= 0) {
                    getMapLayers(m)[layerIndex].style = layerInfo.style;
                }
                viewEvents.updateMapView({ dirty: 'style' });
            }
            return maps;
        })
    );
};

const editCurrentMap = (mapper: (i: IMapInfo) => IMapInfo) =>
    fromNullable(getCurrentMap()).map(mid =>
        dispatch('data/maps', mapsRemote =>
            mapRemote(mapsRemote, maps => {
                const idx = maps.findIndex(m => m.id === mid);
                if (idx !== -1) {
                    maps[idx] = mapper(maps[idx]);
                }
                return maps;
            })
        )
    );

export const setMapBaseLayer = (id: string) =>
    editCurrentMap(info => {
        activityLogger(clickAction('baselayer-switch', id));
        info.baseLayer = [id];
        return info;
    });

export const addMapGroup = (id: string) =>
    editCurrentMap(info => {
        activityLogger(clickAction('baselayer-switch', id));
        getBaseLayersFromGroup(id).map(layers => (info.baseLayer = layers));
        return info;
    });

export const delMapBaseLayer = (id: string) =>
    editCurrentMap(info => {
        activityLogger(clickAction('baselayer-switch', id));
        info.baseLayer = ensureArray(info.baseLayer).filter(b => b !== id);
        return info;
    });

const moveMapBaseLayer = (dir: 'up' | 'down') => (id: string) =>
    editCurrentMap(info => {
        const bls = ensureArray(info.baseLayer);
        const i = bls.indexOf(id);
        if (i < 0) {
            return info;
        }
        if (dir === 'down' && i < bls.length - 1) {
            bls[i] = bls[i + 1];
            bls[i + 1] = id;
        }
        if (dir === 'up' && i > 0) {
            bls[i] = bls[i - 1];
            bls[i - 1] = id;
        }
        info.baseLayer = bls;
        return info;
    });

export const moveMapBaseLayerUp = moveMapBaseLayer('up');
export const moveMapBaseLayerDown = moveMapBaseLayer('down');

export const setMapBaseLayerInService = (service: string) => (layer: string) =>
    setMapBaseLayer(`${service}/${layer}`);

export const delMapBaseLayerInService = (service: string) => (layer: string) =>
    delMapBaseLayer(`${service}/${layer}`);

export const setCurrentLayer = (id: string) => {
    dispatch('app/current-layer', () => id);
    // unsetCurrentFeature();
    clearSelectedFeatures();
    dispatch('component/table', state => ({
        ...state,
        selected: [],
        loaded: 'none',
    }));
};

export const selectFeature = (layerId: string, featureId: string | number) =>
    dispatch('app/selected-features', paths =>
        paths
            .filter(p => p.layerId !== layerId || p.featureId !== featureId)
            .concat({ layerId, featureId })
    );
export const deselectFeature = (
    layerId: string,
    featureId: string | number
) => {
    dispatch('app/selected-features', paths =>
        paths.filter(p => p.layerId !== layerId || p.featureId !== featureId)
    );

    getCurrentLayerOpt().map(lid => {
        if (layerId === lid) {
            dispatch('component/table', state => ({
                ...state,
                selected: state.selected.filter(
                    ({ from }) => from !== featureId
                ),
            }));
        }
    });
};

const {
    clearCurrentFeatureById,
    retryCurrentFeatureById,
    checkCurrentFeatureById,
} = iife(() => {
    const maxTry = 1000;
    let timeout: number | null = null;
    let lid: string | null = null;
    let fid: string | number | null = null;
    const clearCurrentFeatureById = () => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
    };
    const retryCurrentFeatureById = (
        layerInfoId: string,
        id: string | number,
        tries: number
    ) => {
        clearCurrentFeatureById();
        lid = layerInfoId;
        fid = id;
        timeout = window.setTimeout(() => {
            setCurrentFeatureById(layerInfoId, id, tries);
        }, 100);
    };
    const checkCurrentFeatureById = (
        layerInfoId: string,
        id: string | number,
        tries: number
    ) =>
        timeout === null ||
        (layerInfoId === lid && id === fid && tries < maxTry);

    return {
        clearCurrentFeatureById,
        retryCurrentFeatureById,
        checkCurrentFeatureById,
    };
});

export const setCurrentFeatureById = (
    layerInfoId: string,
    id: string | number,
    tries = 0
) => {
    if (checkCurrentFeatureById(layerInfoId, id, tries)) {
        scopeOption()
            .let('data', getLayerDataFromInfo(layerInfoId))
            .let('feature', s =>
                fromNullable(s.data.features.find(f => f.id === id))
            )
            .map(() => {
                clearCurrentFeatureById();
                assign('app/current-layer', layerInfoId);
                selectFeature(layerInfoId, id);
            })
            .getOrElseL(() =>
                retryCurrentFeatureById(layerInfoId, id, tries + 1)
            );
    } else {
        clearCurrentFeatureById();
    }
};

// export const selectFeatureList = (data: Feature[]) =>
//     assign('app/selected-features', data);
export const clearSelectedFeatures = () => {
    clearSelectedRow();
    assign('app/selected-features', []);
};

export const pushFeaturePathToSelection = (
    layerInfoId: string,
    id: string | number
) =>
    scopeOption()
        .let('data', getLayerDataFromInfo(layerInfoId))
        .let('feature', s =>
            fromNullable(s.data.features.find(f => f.id === id))
        )
        .map(() => {
            assign('app/current-layer', layerInfoId);
            selectFeature(layerInfoId, id);
        });

// export const pushFeaturePathToSelection = (
//     layerInfoId: string,
//     id: string | number
// ) =>
//     scopeOption()
//         .let('data', getLayerDataFromInfo(layerInfoId))
//         .let('feature', s =>
//             fromNullable(s.data.features.find(f => f.id === id))
//         )
//         .map(({ feature }) => {
//             assign('app/current-layer', layerInfoId);
//             dispatch('app/selected-features', fs =>
//                 fs
//                     .filter(f => f.feature.id !== feature.id)
//                     .concat({ feature, layerId: layerInfoId })
//             );
//         });

export const clearMap = () => {
    assign('app/current-map', null);
    assign('app/current-layer', null);
    // unsetCurrentFeature();
    clearSelectedFeatures();
    dispatch('component/table', state => {
        state.selected = [];
        return state;
    });
    clearStreams();
};

export const setPrintTitle = (customTitle: MessageRecord) =>
    dispatch('component/print', s => ({ ...s, customTitle }));

export const resetPrintTitle = () =>
    dispatch('component/print', s => ({ ...s, customTitle: null }));

export const loadMetadata = (mid: string) =>
    fetchDatasetMetadata(getApiUrl(`metadatas/${mid}`))
        .then(md =>
            dispatch('data/datasetMetadata', state => {
                state[md.id] = md;
                return state;
            })
        )
        .catch(err => logger(`Failed to load MD ${mid}: ${err}`));

export const selectTableView = () => assign('app/table/selected', true);
export const deselectTableView = () => assign('app/table/selected', false);

export const setDefaultCollapsible = () =>
    getMapList(getMaps()).map(m => setCollapsible(`description-${m.id}`, true));

logger('loaded');
