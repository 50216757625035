import tr, { fromRecord } from 'sdi/locale';

import { DIV, H2, A, SPAN } from 'sdi/components/elements';
import { navigateMap } from '../events/route';
import { getLinks, getMapUrlFromView } from '../queries/map';
import { notEmpty } from 'sdi/util';
import { icon } from 'sdi/components/button';

export const renderRelated = () => {
    const links = getLinks('forward')
        .fold([], ls =>
            ls
                .filter(l => l.status === 'published')
                .map(l =>
                    DIV(
                        'link link--map forward',
                        icon('play'),
                        SPAN(
                            'label',
                            A(
                                {
                                    href: getMapUrlFromView(l.id),
                                    onClick: e => {
                                        e.preventDefault(); // don't follow the link when just clicking, but let use de right-click functionalities
                                        navigateMap(l.id || '###');
                                    },
                                },
                                fromRecord(l.title)
                            )
                        )
                    )
                )
        )
        .concat(
            getLinks('backward').fold([], ls =>
                ls
                    .filter(l => l.status === 'published')
                    .map(l =>
                        DIV(
                            'link link--map backward',
                            icon('play'),
                            SPAN(
                                'label',
                                A(
                                    {
                                        href: getMapUrlFromView(l.id),
                                        onClick: e => {
                                            e.preventDefault(); // don't follow the link when just clicking, but let use de right-click functionalities
                                            navigateMap(l.id || '###');
                                        },
                                    },
                                    fromRecord(l.title)
                                )
                            )
                        )
                    )
            )
        );

    return notEmpty(links).map(l =>
        DIV(
            { className: 'related-maps' },
            H2({}, tr.view('relatedMapsLabel')),
            ...l
        )
    );
};
