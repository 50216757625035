/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import MapEvent from 'ol/MapEvent';
import Map from 'ol/Map';
import * as control from 'ol/control';
import { SetScaleLine, IMapScale, zoomIn, IMapViewData } from '.';
import { DIV } from '../components/elements';
import { MessageRecord } from '../source';
import tr, { fromRecord } from '../locale';
import { buttonTooltipLeft } from '../components/tooltip';
import { makeIcon } from '../components/button';
import { resetRotate, zoomOut, zoomRegio } from './events';
import { fromNullable, none, Option, some } from 'fp-ts/lib/Option';
import { nameToString } from '../components/button/names';
import baseLayerSwitch from './base-layer-switch';
import {
    Getter as InputGetter,
    Setter as InputSetter,
} from '../components/input/input';
import { Getter, Setter } from '../shape';

const logger = debug('sdi:map/controls');

interface ScaleLineOptions {
    minWidth: number;
    setScaleLine: SetScaleLine;
}

export const scaleLine = (options: ScaleLineOptions) => {
    let previousCount = -1;
    let previousWidth = -1;
    let previousSuffix = '';
    const render = (mapEvent: MapEvent) => {
        const frameState = mapEvent.frameState;
        if (!frameState) {
            return;
        }

        const viewState = frameState.viewState;
        if (!viewState) {
            return;
        }

        let pointResolution = viewState.resolution;
        const nominalCount = options.minWidth * pointResolution;
        let suffix = '';

        if (nominalCount < 1) {
            suffix = 'mm';
            pointResolution *= 1000;
        } else if (nominalCount < 1000) {
            suffix = 'm';
        } else {
            suffix = 'km';
            pointResolution /= 1000;
        }

        let i =
            3 *
            Math.floor(
                Math.log(options.minWidth * pointResolution) / Math.log(10)
            );
        let count: number;
        let width: number;
        const LEADING_DIGITS = [1, 2, 5];
        // tslint:disable-next-line:no-constant-condition
        while (true) {
            count =
                LEADING_DIGITS[((i % 3) + 3) % 3] *
                Math.pow(10, Math.floor(i / 3));
            width = Math.round(count / pointResolution);
            if (isNaN(width)) {
                return;
            } else if (width >= options.minWidth) {
                break;
            }
            i += 1;
        }

        if (
            count !== previousCount ||
            width !== previousWidth ||
            suffix !== previousSuffix
        ) {
            logger(`scaleline ${width}px | ${count}${suffix}`);
            previousCount = count;
            previousWidth = width;
            previousSuffix = suffix;
            options.setScaleLine(count, suffix, width);
        }
    };

    return new control.ScaleLine({ render });
};

export const renderScaleline = (sl: IMapScale) =>
    DIV(
        { className: 'map-scale' },
        DIV({ className: 'map-scale-label' }, `${sl.count} ${sl.unit}`),
        DIV(
            { className: 'map-scale-line', style: { width: `${sl.width}px` } },
            DIV({ className: 'quarter' }),
            DIV({ className: 'quarter' }),
            DIV({ className: 'half' })
        )
    );

// const setTooltip =
//     (target: Element, selector: string, tooltip: string, tooltipPos: TooltipPosition) => {
//         const observer = new MutationObserver(
//             (_mutationsList, _observer) => {
//                 target.querySelectorAll(selector)
//                     .forEach((n) => {
//                         n.setAttribute('data-tooltip', tooltip);
//                         n.setAttribute('data-tooltip-position', tooltipPos);
//                         n.setAttribute('aria-label', tooltip);
//                     });
//                 observer.disconnect();
//             });

//         observer.observe(target, { childList: true });
//     };

// export const zoomControl =
//     (target: Element) => {
//         setTooltip(target, '.zoom-in', tr.core('zoomIn'), 'left');
//         setTooltip(target, '.zoom-out', tr.core('zoomOut'), 'left');
//         return (
//             new control.Zoom({
//                 target,
//                 className: 'zoom',
//                 zoomInLabel: '\uF067',
//                 zoomOutLabel: '\uF068',
//                 zoomInTipLabel: ' ',
//                 zoomOutTipLabel: ' ',
//             })
//         );
//     };

const zoomInButton = makeIcon('navigate', 3, 'plus', {
    position: 'left',
    text: () => tr.core('zoomIn'),
});
const zoomOutButton = makeIcon('navigate', 3, 'minus', {
    position: 'left',
    text: () => tr.core('zoomOut'),
});
// const northButton = makeIcon('navigate', 3, 'arrow-up', { position: 'left', text: () => tr.core('north') });

export const renderZoomInButton = (dispatchView: Setter<IMapViewData>) =>
    DIV(
        'zoom-in-btn',
        zoomInButton(() => zoomIn(dispatchView))
    );

export const renderZoomOutButton = (dispatchView: Setter<IMapViewData>) =>
    DIV(
        'zoom-out-btn',
        zoomOutButton(() => zoomOut(dispatchView))
    );

const renderZoomRegioButton = (dispatchView: Setter<IMapViewData>) =>
    DIV(
        'zoom-rbc',
        buttonTooltipLeft(
            tr.core('zoomRegio'),
            {
                onClick: () => zoomRegio(dispatchView),
            },
            DIV('icon-rbc', '')
        )
    );

export const renderNorthButton = (
    dispatchView: Setter<IMapViewData>,
    getView: Getter<IMapViewData>
) =>
    DIV(
        'btn--north',
        buttonTooltipLeft(
            tr.core('north'),
            {
                onClick: () => resetRotate(dispatchView),
            },
            DIV(
                {
                    className: 'icon',
                    style: {
                        transform: `rotate(${getView().rotation}rad)`,
                    },
                },
                nameToString('north')
            )
        )
    );

// const northButton = (dispatchView: Setter<IMapViewData>, getView: Getter<IMapViewData>) =>
//     buttonTooltipLeft(
//         tr.core('north'),
//         {
//             className: 'btn--north',
//             onClick: () => resetRotate(dispatchView),
//         },
//         DIV({
//             className: 'btn__icon',
//             style: {
//                 transform: `rotate(${getView().rotation}rad)`,
//             },
//         })
//     );

const renderFullScreenButton = (onclick: () => void) =>
    makeIcon('expand', 3, 'expand', {
        position: 'left',
        text: () => tr.core('fullscreen'),
    })(onclick);

const requestFullScreen = (element: HTMLElement) => {
    if (element.requestFullscreen) {
        element.requestFullscreen();
    } else if ((element as any)['msRequestFullscreen']) {
        (element as any)['msRequestFullscreen']();
    } else if ((element as any)['webkitRequestFullscreen']) {
        (element as any)['webkitRequestFullscreen']();
    }
};

export const enterFullScreen = () =>
    getOlMap().map(map => requestFullScreen(map.getTargetElement()));

/**
 * This thing is really a hack, but should work smoothly - pm
 */
export const { storeOlMap, getOlMap } = (() => {
    let stored: Option<Map> = none;

    const storeOlMap = (map: Map) => (stored = some(map));

    const getOlMap = () => stored;

    return {
        storeOlMap,
        getOlMap,
    };
})();

const geoLocateBtn = makeIcon('navigate', 2, 'crosshairs-gps', {
    text: () => tr.core('gpsTracker'),
    position: 'left',
});
export const renderGeoLocateBtn = (startTracker: () => void) =>
    geoLocateBtn(startTracker, 'mobile tracker');

type BaseLayerFunctions = {
    setBaseLayer: InputSetter<string>;
    getBaseLayer: InputGetter<string[]>;
};

export const mapRightTools = (
    setView: Setter<IMapViewData>,
    getView: Getter<IMapViewData>,
    startTracker: (() => void) | null = null,
    baseLayerFunctions: BaseLayerFunctions | null = null
) =>
    DIV(
        'control-box',
        DIV(
            '',
            fromNullable(startTracker).map(st => renderGeoLocateBtn(st))
        ),
        renderNorthButton(setView, getView),
        renderZoomInButton(setView),
        renderZoomOutButton(setView),
        renderFullScreenButton(() => enterFullScreen()),
        fromNullable(baseLayerFunctions).map(blf =>
            baseLayerSwitch(blf.getBaseLayer, blf.setBaseLayer)
        )
    );

export const mapRightToolsWithRegio = (
    dispatch: Setter<IMapViewData>,
    getView: Getter<IMapViewData>
) =>
    DIV(
        'control-box',
        renderNorthButton(dispatch, getView),
        renderZoomInButton(dispatch),
        renderZoomOutButton(dispatch),
        renderZoomRegioButton(dispatch),
        renderFullScreenButton(() => enterFullScreen())
    );

export const mapRightToolsSimplified = (setView: Setter<IMapViewData>) =>
    DIV(
        'control-box',
        renderZoomInButton(setView),
        renderZoomOutButton(setView)
    );
// const northArrow = document.createElement('span');
// northArrow.setAttribute('class', 'north-arrow');

// export const rotateControl =
//     (target: Element) => {
//         setTooltip(target, '.rotate', tr.core('north'), 'left');
//         return (
//             new control.Rotate({
//                 target,
//                 className: 'rotate',
//                 autoHide: false,
//                 label: northArrow,
//                 tipLabel: ' ',
//             })
//         );
//     };

// const fsInNode = document.createElement('button');
// fsInNode.setAttribute('class', 'fullscreen-in');
// const fsOutNode = document.createElement('button');
// fsOutNode.setAttribute('class', 'fullscreen-out');

// export const fullscreenControl =
//     (target: Element) => {
//         setTooltip(target, '.fullscreen', tr.core('fullscreen'), 'left');
//         return (
//             new control.FullScreen({
//                 target,
//                 className: 'fullscreen',
//                 label: fsInNode,
//                 labelActive: fsOutNode,
//                 tipLabel: ' ',
//             })
//         );
//     };

export type LoadingMonitorListener = (ms: MessageRecord[]) => void;

class LoadingMonitor {
    private loadingLayers: MessageRecord[] = [];
    private hs: LoadingMonitorListener[] = [];

    add(m: MessageRecord | null) {
        if (m !== null) {
            this.loadingLayers = this.loadingLayers
                .filter(r => fromRecord(r) !== fromRecord(m))
                .concat([m]);
            this.emitUpdate();
            logger(
                `LoadingMon.add ${fromRecord(m)} ${this.loadingLayers.length}`
            );
        }
    }

    remove(m: MessageRecord | null) {
        if (m !== null) {
            this.loadingLayers = this.loadingLayers.filter(
                r => fromRecord(r) !== fromRecord(m)
            );
            this.emitUpdate();
            logger(
                `LoadingMon.remove ${fromRecord(m)} ${
                    this.loadingLayers.length
                }`
            );
        }
    }

    onUpdate(h: LoadingMonitorListener) {
        this.hs.push(h);
    }

    private emitUpdate() {
        logger(`LoadingMon.emitUpdate  ${this.hs.length}`);
        const layers = this.loadingLayers.map(m => m);
        this.hs.forEach(h => h(layers));
    }
}

export const loadingMon = () => new LoadingMonitor();

logger('loaded');
