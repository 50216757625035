/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { factory, button } from './base';
import { IconName, nameToString } from './names';
import { Collection } from '../../util';
import { Translated } from '../../locale';
import { Tooltip } from '../tooltip';
import { Option } from 'fp-ts/lib/Option';
import { SPAN } from '../elements';

export const MAX_DURATION = 5000;

export interface ButtonQuerySet {
    allButtons(): any;
    hasKey(k: string): boolean;
    isActive(k: string): boolean;
    duration(k: string): number;
}

export interface ButtonEventSet {
    setStep(k: string, s: any): void;
}

export type ButtonGetter = () => ButtonComponent;
export type ButtonSetter = (h: (a: ButtonComponent) => ButtonComponent) => void;

export type ButtonName =
    | 'add'
    | 'arrow-right'
    | 'bar-chart'
    | 'cancel'
    | 'clear'
    | 'close'
    | 'collapsed'
    | 'collapse'
    | 'confirm'
    | 'draft'
    | 'edit'
    | 'expanded'
    | 'expand'
    | 'export'
    | 'filter'
    | 'fullscreen'
    | 'info'
    | 'layerInfoSettings'
    | 'layerInfoSwitchMap'
    | 'layerInfoSwitchTable'
    | 'login'
    | 'logout'
    | 'move-down'
    | 'move-up'
    | 'navigate'
    | 'next'
    | 'open'
    | 'pie-chart'
    | 'prev'
    | 'publish'
    | 'remove'
    | 'reset'
    | 'save'
    | 'search'
    | 'select'
    | 'settings'
    | 'start'
    | 'switch'
    | 'table'
    | 'tag'
    | 'text'
    | 'toggle-off'
    | 'toggle-on'
    | 'translate'
    | 'upload'
    | 'validate'
    | 'view'
    | 'zoomOnFeature'
    | 'zoomOnLayer'
    | 'load';

export type ButtonSize = 1 | 2 | 3;
export const defaultSize = (): ButtonSize => 2;

export interface ButtonTypeIcon {
    type: 'Icon';
    tooltip: Tooltip;
    icon: IconName;
}
export interface ButtonTypeLabel {
    type: 'Label';
    label: () => Translated; // lazy, because of needing store to be configured
}
export interface ButtonTypeLabelAndIcon {
    type: 'LabelAndIcon';
    icon: IconName;
    label: () => Translated;
}

export type ButtonConfig = (
    | ButtonTypeIcon
    | ButtonTypeLabel
    | ButtonTypeLabelAndIcon
) & {
    size: ButtonSize;
    name: ButtonName;
    id: Option<string>;
};

export type Step = 'initial' | 'active';

interface ButtonState {
    step: Step;
    since: number;
}

export type ButtonComponent = Collection<ButtonState>;

export default factory;

export const { make, makeIcon, makeLabel, makeLabelAndIcon } = button();

export const icon = (name: IconName, extraClass = '' as string) =>
    SPAN(`fa icon ${extraClass}`, nameToString(name));
