import { none, some } from 'fp-ts/lib/Option';
import { iife } from '../../lib';

const reservedNames = {
    'tags+plus': 0x0000,
};

export const pictoCircle = {
    CircleFill: 0xee00,
    CircleFillDot: 0xee01,
    CircleThin: 0xee02,
    CircleThinDot: 0xee03,
    CircleThick: 0xee04,
    CircleThickDot: 0xee05,
};

export const pictoTriangle = {
    // TriangleEFill: 0xee06,
    // TriangleEFillDot: 0xee07,
    // TriangleEThin: 0xee08,
    // TriangleEThinDot: 0xee09,
    // TriangleEThick: 0xee0a,
    // TriangleEThickDot: 0xee0b,
    TriangleNFill: 0xee0c,
    TriangleNFillDot: 0xee0d,
    TriangleNThin: 0xee0e,
    TriangleNThinDot: 0xee0f,
    TriangleNThick: 0xee10,
    TriangleNThickDot: 0xee11,
    // TriangleWFill: 0xee12,
    // TriangleWFillDot: 0xee13,
    // TriangleWThin: 0xee14,
    // TriangleWThinDot: 0xee15,
    // TriangleWThick: 0xee16,
    // TriangleWThickDot: 0xee17,
    TriangleSFill: 0xee18,
    TriangleSFillDot: 0xee19,
    TriangleSThin: 0xee1a,
    TriangleSThinDot: 0xee1b,
    TriangleSThick: 0xee1c,
    TriangleSThickDot: 0xee1d,
};
export const pictoSquare = {
    SquareEFill: 0xee1e,
    SquareEFillDot: 0xee1f,
    SquareEThin: 0xee20,
    SquareEThinDot: 0xee21,
    SquareEThick: 0xee22,
    SquareEThickDot: 0xee23,
    // SquareNFill: 0xee24,
    // SquareNFillDot: 0xee25,
    // SquareNThin: 0xee26,
    // SquareNThinDot: 0xee27,
    // SquareNThick: 0xee28,
    // SquareNThickDot: 0xee29,
};
export const pictoLosange = {
    LosangeEFill: 0xee2a,
    LosangeEFillDot: 0xee2b,
    LosangeEThin: 0xee2c,
    LosangeEThinDot: 0xee2d,
    LosangeEThick: 0xee2e,
    LosangeEThickDot: 0xee2f,
    // LosangeNFill: 0xee30,
    // LosangeNFillDot: 0xee31,
    // LosangeNThin: 0xee32,
    // LosangeNThinDot: 0xee33,
    // LosangeNThick: 0xee34,
    // LosangeNThickDot: 0xee35,
};
export const pictoPentagon = {
    PentagonEFill: 0xee36,
    PentagonEFillDot: 0xee37,
    PentagonEThin: 0xee38,
    PentagonEThinDot: 0xee39,
    PentagonEThick: 0xee3a,
    PentagonEThickDot: 0xee3b,
    // PentagonNFill: 0xee3c,
    // PentagonNFillDot: 0xee3d,
    // PentagonNThin: 0xee3e,
    // PentagonNThinDot: 0xee3f,
    // PentagonNThick: 0xee40,
    // PentagonNThickDot: 0xee41,
    // PentagonWFill: 0xee42,
    // PentagonWFillDot: 0xee43,
    // PentagonWThin: 0xee44,
    // PentagonWThinDot: 0xee45,
    // PentagonWThick: 0xee46,
    // PentagonWThickDot: 0xee47,
    // PentagonSFill: 0xee48,
    // PentagonSFillDot: 0xee49,
    // PentagonSThin: 0xee4a,
    // PentagonSThinDot: 0xee4b,
    // PentagonSThick: 0xee4c,
    // PentagonSThickDot: 0xee4d,
};
export const pictoHexagon = {
    HexagonEFill: 0xee4e,
    HexagonEFillDot: 0xee4f,
    HexagonEThin: 0xee50,
    HexagonEThinDot: 0xee51,
    HexagonEThick: 0xee52,
    HexagonEThickDot: 0xee53,
    // HexagonNFill: 0xee54,
    // HexagonNFillDot: 0xee55,
    // HexagonNThin: 0xee56,
    // HexagonNThinDot: 0xee57,
    // HexagonNThick: 0xee58,
    // HexagonNThickDot: 0xee59,
};
const pictos = {
    ...pictoCircle,
    ...pictoTriangle,
    ...pictoSquare,
    ...pictoLosange,
    ...pictoPentagon,
    ...pictoHexagon,
};

const pictoApp = {
    LogoBrusolar: 0xeea0,
    LogoBrugeotool: 0xeea1,
    LogoBruwater: 0xeea2,
    LogoInfiltrasoil: 0xeea3,
};

// const baseNames = {
//     /**
//      * LGU
//      * renamed
//      * arrows-alt-h -> arrows-h (f07e)
//      */
//     'arrows-alt-h': 0xf07e,
//     'arrows-h': 0xf07e,
//     /**
//      * LGU
//      * renamed and recoded
//      * exchange-alt -> exchange (f0ec)
//      */
//     // 'exchange-alt': 0xf362,
//     'exchange-alt': 0xf0ec,
//     /**
//      * LGU
//      * THose glyphs have been renamed and recoded in fokawesome
//      * external-link-alt -> external-link (f08e)
//      * external-link-square-alt -> external-link-square (f14c)
//      */
//     // 'external-link-alt': 0xf35d,
//     // 'external-link-square-alt': 0xf360,
//     'external-link-alt': 0xf08e,
//     'external-link-square-alt': 0xf14c,
//     /**
//      * LGU
//      * Used in CSS but missing here, or through alias save
//      * floppy-o (f0c7)
//      */
//     'floppy-o': 0xf0c7,
//     /**
//      * LGU
//      * added open map, dont delete
//      */
//     'map-o': 0xf278,
//     /**
//      * LGU
//      * map-marker-alt -> map-marker (f041)
//      */
//     // 'map-marker-alt': 0xf3c5,
//     'map-marker-alt': 0xf041,
//     'map-marker': 0xf041,
//     /**
//      * LGU
//      * pen-square remapped to pencil-square-o (f044)
//      * pencil-alt recoded and renamed to pencil (f040)
//      */
//     // 'pen-square': 0xf14b,
//     // 'pencil-alt': 0xf303,
//     'pen-square': 0xf044,
//     'pencil-alt': 0xf040,
//     pencil: 0xf040,
//     /**
//      * LGU
//      * used in CSS
//      * share-alt (f1e0)
//      */
//     'share-alt': 0xf1e0,
//     /**
//      * LGU
//      * THose glyphs have been renamed and recoded in fokawesome
//      * sign-in-alt -> sign-in (f090)
//      * sign-out-alt -> sign-out (f08b)
//      */
//     // 'sign-in-alt': 0xf2f6,
//     // 'sign-out-alt': 0xf2f5,

// };

const baseNames = {
    'angle-down': 0xf107,
    'angle-right': 0xf105,
    'angle-up': 0xf106,
    archive: 0xf187,
    'arrow-down': 0xf063,
    'arrow-right': 0xf061,
    'arrow-up': 0xf062,
    'arrows-alt': 0xf0b2,
    'arrows-alt-h': 0xf07e,
    'arrows-h': 0xf07e,
    bars: 0xf0c9,
    bath: 0xf2cd,
    book: 0xf02d,
    building: 0xf1ad,
    'calendar-alt': 0xf073,
    'caret-down': 0xf0d7,
    'caret-right': 0xf0da,
    'caret-up': 0xf0d8,
    'chart-bar': 0xf080,
    'chart-line': 0xf201,
    check: 0xf00c,
    'check-square': 0xf14a,
    'check-square-o': 0xf046,
    'chevron-circle-down': 0xf13a,
    'chevron-down': 0xf078,
    'chevron-left': 0xf053,
    'chevron-right': 0xf054,
    'chevron-up': 0xf077,
    circle: 0xf111,
    'circle-notch': 0xf1ce,
    cog: 0xf013,
    cogs: 0xf085,
    comment: 0xf075,
    compress: 0xf066,
    copy: 0xf0c5,
    crosshairs: 0xf05b,
    'crosshairs-gps': 0xef1b,
    'dot-circle': 0xf192,
    download: 0xf019,
    edit: 0xf044,
    'ellipsis-h': 0xf141,
    envelope: 0xf0e0,
    'exchange-alt': 0xf0ec,
    'exclamation-triangle': 0xf071,
    expand: 0xf065,
    'external-link-alt': 0xf08e,
    eye: 0xf06e,
    'eye-slash': 0xf070,
    file: 0xf15b,
    'file-alt': 0xf15c,
    'file-pdf': 0xf1c1,
    filter: 0xf0b0,
    'floppy-o': 0xf0c7,
    history: 0xf1da,
    home: 0xf015,
    image: 0xf03e,
    info: 0xf129,
    'info-circle': 0xf05a,
    'life-ring': 0xf1cd,
    link: 0xf0c1,
    list: 0xf03a,
    'list-alt': 0xf022,
    'list-ul': 0xf0ca,
    'location-arrow': 0xf124,
    lock: 0xf023,
    map: 0xf279,
    'map-marker': 0xf041,
    'map-marker-alt': 0xf041,
    minus: 0xf068,
    'mouse-pointer': 0xf245,
    north: 0xef1a,
    pencil: 0xf040,
    'pencil-alt': 0xf040,
    play: 0xf04b,
    'play-circle': 0xf144,
    plus: 0xf067,
    print: 0xf02f,
    'quote-right': 0xf10e,
    save: 0xf0c7,
    search: 0xf002,
    'search-minus': 0xf010,
    'search-plus': 0xf00e,
    'share-alt': 0xf1e0,
    'sign-in-alt': 0xf090,
    'sign-out-alt': 0xf08b,
    sort: 0xf0dc,
    'sort-down': 0xf0dd,
    'sort-up': 0xf0de,
    'spatial-query': 0xef1c,
    spinner: 0xf110,
    square: 0xf0c8,
    'square-o': 0xf096,
    star: 0xf005,
    stop: 0xf04d,
    sun: 0xf185,
    sync: 0xf021,
    table: 0xf0ce,
    tag: 0xf02b,
    tags: 0xf02c,
    times: 0xf00d,
    'times-circle': 0xf057,
    'toggle-off': 0xf204,
    'toggle-on': 0xf205,
    trash: 0xf1f8,
    'trash-o': 0xf014,
    undo: 0xf0e2,
    unlink: 0xf127,
    upload: 0xf093,
    user: 0xf007,
    'user-plus': 0xf234,
    users: 0xf0c0,
    wifi: 0xf1eb,
};

const names = {
    ...reservedNames,
    ...pictos,
    ...pictoApp,
    ...baseNames,
};

type ReservedNames = typeof reservedNames;
type ReservedNameKey = keyof ReservedNames;

export type Names = typeof names;

export type IconName = keyof Names;

export const nameToCode = (n: IconName) => names[n];

const fcp = String.fromCodePoint;

export const nameToString = (n: IconName) => {
    if (n in reservedNames) {
        switch (n as ReservedNameKey) {
            case 'tags+plus':
                return fcp(names['tags']) + ' ' + fcp(names['plus']);
        }
    }

    return fcp(names[n]);
};

export const tryNameToString = (name: string) =>
    name in names ? some(fcp(names[name as IconName])) : none;

export const mapPictos = <T>(
    which:
        | 'circle'
        | 'triangle'
        | 'square'
        | 'losange'
        | 'pentagon'
        | 'hexagon',
    f: (s: IconName, value: number) => T
) => {
    const dict = iife(() => {
        switch (which) {
            case 'circle':
                return pictoCircle;
            case 'triangle':
                return pictoTriangle;
            case 'square':
                return pictoSquare;
            case 'losange':
                return pictoLosange;
            case 'pentagon':
                return pictoPentagon;
            case 'hexagon':
                return pictoHexagon;
        }
    });
    return Object.keys(dict).map((k: IconName) => f(k, names[k]));
};
