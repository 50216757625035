/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { fromNullable, fromPredicate, none, Option } from 'fp-ts/lib/Option';

import { getContext, IOLContext, polygonStyle } from '../../map/style';
import { DIV, NodeOrOptional, SPAN } from '../elements';
import { fromRecord } from '../../locale';
import {
    ILayerInfo,
    PolygonStyleConfig,
    PolygonStyleConfigSimple,
    PolygonStyleConfigDiscrete,
    PolygonStyleConfigContinuous,
    Inspire,
    PatternStyle,
    PolygonDiscreteGroup,
    PolygonInterval,
} from '../../source';
import {
    applyResolutionStyle,
    renderSimpleItemLabel,
    LegendActions,
    divWithOpacityVisibilityClass,
} from '.';
import { getAlphaNb } from '../input';
import { notEmpty } from '../../util';

const logger = debug('sdi:legend-polygon');

const polygonGeometry = new Polygon([
    [
        [0, 0],
        [100, 0],
        [100, 100],
        [0, 100],
        [0, 0],
    ],
]);

interface Stroke {
    width: number;
    color: string;
}

type StrokeSource = {
    strokeWidth: number;
    strokeColor: string;
    pattern?: PatternStyle;
};

const makeStroke = (config: StrokeSource): Stroke | null => {
    if (config.strokeWidth >= 0.5) {
        // if (config.pattern && config.patternColor === undefined) {
        //     return null;
        // }
        return {
            width: config.strokeWidth,
            color: config.strokeColor,
        };
    }
    return null;
};

const item = (
    geomType: string,
    dataUrl: string,
    label: string,
    stroke: Stroke | null,
    actions: NodeOrOptional
) =>
    DIV(
        { className: `legend-item ${geomType}` },
        actions,
        DIV(
            { className: 'item-style' },
            DIV({
                style: {
                    width: '100%',
                    height: '50%',
                    backgroundImage: `url(${dataUrl})`,
                    backgroundPosition: 'center',
                    borderWidth: stroke === null ? '0' : `${stroke.width}px`,
                    borderColor: stroke === null ? 'inherit' : stroke.color,
                    borderStyle: stroke === null ? 'none' : 'solid',
                },
            })
        ),
        // IMG({ src: dataUrl })),
        DIV({ className: 'item-label' }, SPAN({}, label))
    );

const renderSimple = (
    config: PolygonStyleConfigSimple,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    ctx: IOLContext,
    actions: LegendActions
) => {
    const { canvas, olContext } = ctx;
    const styles = applyResolutionStyle(
        polygonStyle(config),
        new Feature(polygonGeometry)
    );
    styles.forEach(style => {
        olContext.setStyle(style);
        olContext.drawGeometry(polygonGeometry);
    });
    const renderActions = fromNullable(actions.layer).map(a => a(layerInfo));
    const label = renderSimpleItemLabel(layerInfo, md);

    return divWithOpacityVisibilityClass(
        layerInfo,
        0,
        item('polygon', canvas.toDataURL(), label, makeStroke(config), none),
        renderActions
    );
};

type PolygonItem = PolygonDiscreteGroup | PolygonInterval;
const allSameOpacity = (items: PolygonItem[]) =>
    notEmpty(items)
        .map(elements =>
            elements.every(
                g =>
                    getAlphaNb(g.fillColor)() ===
                    getAlphaNb(items[0].fillColor)()
            )
        )
        .getOrElse(true);

const renderDiscrete = (
    config: PolygonStyleConfigDiscrete,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    actions: LegendActions
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = polygonStyle(config);
    const renderItemAction = (idx: number) =>
        fromNullable(actions.item).map(action => action(layerInfo, idx));
    const renderOpacitySelector = fromNullable(actions.layer).map(op =>
        op(layerInfo)
    );

    const groups = config.groups;
    return notEmpty(groups).map(gps => {
        const items = gps.map((group, groupIdx) =>
            notEmpty(group.values).map(() => {
                canvasContext.clearRect(0, 0, 100, 100);
                const f = new Feature(polygonGeometry);
                f.set(config.propName, group.values[0]);
                const styles = applyResolutionStyle(styleFn, f);
                styles.forEach(style => {
                    olContext.drawFeature(f, style);
                });
                const stroke = group.hidden ? null : makeStroke(group);
                return divWithOpacityVisibilityClass(
                    layerInfo,
                    groupIdx,
                    item(
                        'polygon',
                        canvas.toDataURL(),
                        fromRecord(group.label),
                        stroke,
                        renderItemAction(groupIdx)
                    )
                );
            })
        );
        // TODO: use this to only have layer opacity selector when all items have the same default opacity
        // const renderOpacity = fromPredicate<PolygonDiscreteGroup[]>(gList =>
        //     allSameOpacity(gList)
        // )(groups).chain(() => opacitySelectorOpt.map(op => op(layerInfo)));
        return DIV('items', renderOpacitySelector, items);
    });
};

const renderContinuous = (
    config: PolygonStyleConfigContinuous,
    layerInfo: ILayerInfo,
    _md: Option<Inspire>,
    ctx: IOLContext,
    actions: LegendActions
) => {
    const { canvas, canvasContext, olContext } = ctx;
    const styleFn = polygonStyle(config);
    const renderItemAction = (idx: number) =>
        fromNullable(actions.item).map(action => action(layerInfo, idx));
    const opacitySelectorOpt = fromNullable(actions.layer);

    const intervals = config.intervals;
    return notEmpty(intervals).map(() => {
        const items = intervals.map((interval, idx) => {
            canvasContext.clearRect(0, 0, 100, 100);
            const f = new Feature(polygonGeometry);
            const v = interval.low + (interval.high - interval.low) / 2;
            f.set(config.propName, v);
            const styles = applyResolutionStyle(styleFn, f);
            styles.forEach(style => {
                olContext.drawFeature(f, style);
            });
            const stroke = interval.hidden ? null : makeStroke(interval);
            return divWithOpacityVisibilityClass(
                layerInfo,
                idx,
                item(
                    'polygon',
                    canvas.toDataURL(),
                    fromRecord(interval.label),
                    stroke,
                    renderItemAction(idx)
                )
            );
        });
        const renderOpacity = fromPredicate<PolygonInterval[]>(iList =>
            allSameOpacity(iList)
        )(intervals).chain(() => opacitySelectorOpt.map(op => op(layerInfo)));
        return DIV('items', renderOpacity, ...items);
    });
};

const render = (
    config: PolygonStyleConfig,
    layerInfo: ILayerInfo,
    md: Option<Inspire>,
    actions = {} as LegendActions
) => {
    const ctx = getContext(100, 100);

    if (ctx) {
        switch (config.kind) {
            case 'polygon-simple':
                return renderSimple(config, layerInfo, md, ctx, actions);
            case 'polygon-discrete':
                return renderDiscrete(config, layerInfo, md, ctx, actions);
            case 'polygon-continuous':
                return renderContinuous(config, layerInfo, md, ctx, actions);
        }
    }

    return [];
};

export default render;

logger('loaded');
