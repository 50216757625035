/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { some, none } from 'fp-ts/lib/Option';

import { loop, getApiUrl, loadTerms } from 'sdi/app';
import { DIV, SPAN, NodeOrOptional, BUTTON } from 'sdi/components/elements';
import header from 'sdi/components/header';
import footer from 'sdi/components/footer';
import tr from 'sdi/locale';
import { loadAllServices } from 'sdi/geocoder/events';

import map from './components/map';
import table from './components/table/feature-collection2';
import feature from './components/feature-view';
import legend, { switcher as legendSwitch } from './components/legend';
import home from './components/home';
import tracker from './components/geo-tracker';
import measure from './components/geo-measure';
import extract from './components/extract';
import print from './components/print';
import query from './components/query';
import modal from './components/modal';
import {
    loadAllBaseLayers,
    loadCategories,
    loadAlias,
    loadUsers,
    loadBaseLayersGroups,
} from './events/app';
import { getLayout, isTableSelected } from './queries/app';

import { viewEvents } from './events/map';
import { AppLayout } from './shape/types';
import { navigate, navigateHome } from './events/route';
import { maintenance } from 'sdi/components/maintenance';
import { icon } from 'sdi/components/button';

const logger = debug('sdi:app');

const renderAppListingButton = () => {
    if (getLayout() !== AppLayout.MapNavigatorFS) {
        return some(
            DIV(
                'app-listwrapper',
                BUTTON(
                    {
                        className: 'navigate app-listview',
                        onClick: () => navigateHome(),
                    },
                    icon('ellipsis-h'),
                    SPAN({ className: 'label' }, tr.view('mapList'))
                )
            )
        );
    }
    return none;
};

const wrappedMain = (name: string, ...elements: NodeOrOptional[]) =>
    DIV(
        'app-inner view-inner',
        modal(),
        header('view', { navigateHome, appElement: renderAppListingButton() }),
        maintenance('view'),
        DIV({ className: `main ${name}` }, ...elements),
        footer()
    );

const renderMapFs = () =>
    isTableSelected().fold(
        wrappedMain(
            'map-fs',
            legendSwitch(),
            legend(),
            map()
            // baseLayerSwitch()
        ),
        () =>
            wrappedMain(
                'map-and-table',
                legendSwitch(),
                legend(),
                DIV('vertical-split', map(), table())
            )
    );

const renderMapAndInfo = () =>
    isTableSelected().fold(
        wrappedMain(
            'map-and-info',
            legendSwitch(),
            legend(),
            map()
            // baseLayerSwitch()
        ),
        () =>
            wrappedMain(
                'map-and-table',
                legendSwitch(),
                legend(),
                DIV('vertical-split', map(), table())
            )
    );

const renderMapAndFeature = () =>
    isTableSelected().fold(
        wrappedMain(
            'map-and-info',
            legendSwitch(),
            feature(),
            map()
            // baseLayerSwitch()
        ),
        () =>
            wrappedMain(
                'map-and-table',
                legendSwitch(),
                feature(),
                DIV('vertical-split', map(), table())
            )
    );

// const renderTableFs = () => wrappedMain('table-fs', table());

// const renderMapAndTable = () =>
//     wrappedMain(
//         'map-and-table',
//         legendSwitch(),
//         legend(),
//         DIV({ className: 'vertical-split' }, map(), table())
//     );

const renderHome = () => wrappedMain('map-navigator-fs', home());

// const renderMapAndTableAndFeature = () =>
//     wrappedMain(
//         'map-and-table',
//         legendSwitch(),
//         feature(),
//         DIV({ className: 'vertical-split' }, map(), table())
//     );

const renderMapAndTracker = () =>
    wrappedMain('map-and-tracker', tracker(), map());

const renderMapAndMeasure = () =>
    wrappedMain('map-and-measure', measure(), map());

const renderMapAndExtract = () =>
    wrappedMain(
        'map-and-extract',
        extract(),
        isTableSelected().fold(map(), () =>
            DIV('vertical-split', map(), table())
        )
    );

const renderPrint = () => wrappedMain('print', print(), map());

const renderQuery = () => wrappedMain('query', query());

const renderQueryPrint = () => wrappedMain('query', query(true));

const renderMain = () => {
    const layout = getLayout();
    logger(`LAYOUT: ${layout}`);
    switch (layout) {
        case AppLayout.MapFS:
            return renderMapFs();
        case AppLayout.MapAndInfo:
            return renderMapAndInfo();
        case AppLayout.MapAndFeature:
            return renderMapAndFeature();
        case AppLayout.MapNavigatorFS:
            return renderHome();
        case AppLayout.MapAndTracker:
            return renderMapAndTracker();
        case AppLayout.MapAndMeasure:
            return renderMapAndMeasure();
        case AppLayout.MapAndExtract:
            return renderMapAndExtract();
        case AppLayout.Print:
            return renderPrint();
        case AppLayout.Query:
            return renderQuery();
        case AppLayout.QueryPrint:
            return renderQueryPrint();
    }
};

// const baseLayers = [
//     'urbis.irisnet.be/urbis_gray',
//     'urbis.irisnet.be/ortho_2016',
// ];

const effects = () => {
    loadAllServices();
    viewEvents.updateMapView({ dirty: 'geo' });
    // baseLayers.forEach(id =>
    //     events.loadBaseLayer(id, getApiUrl(`wmsconfig/${id}`)));
    loadAllBaseLayers(getApiUrl(`wmsconfig/`));
    loadBaseLayersGroups(getApiUrl(`wmsgroups/`));
    loadCategories(getApiUrl(`categories`));
    loadAlias(getApiUrl(`alias`));
    loadUsers();
    navigate();
    tr.init_edited();
    loadTerms();
    // activityLogger(langAction(getLang()));
    // activityLogger(visitAction());
};

const app = loop('view', renderMain, effects);
export default app;

logger('loaded');
