/*
 *  Copyright (C) 2019 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { right, left, Either } from 'fp-ts/lib/Either';
import { fromNullable, some } from 'fp-ts/lib/Option';
import { Coordinate } from 'ol/coordinate';

import { dispatch, query, assign } from 'sdi/shape';
import { getFeatureProp, mapRemote } from 'sdi/source';
import { addLayer, removeLayer } from 'sdi/map';
import tr from 'sdi/locale';

import {
    getBookmarks,
    getBookmarkIndex,
    bookmarkLayerID,
    bookmarkLayerInfo,
    bookmarkLayerName,
    bookmarkMetadata,
    bookmarkMetadataID,
    defaultBookmarks,
} from '../queries/bookmark';
import { mapName } from '../components/map';
import { index } from 'fp-ts/lib/Array';
import { addLayerData } from './app';

const logger = debug('sdi:events/bookmark');

const updateBookmarks = () => {
    removeLayer(mapName, bookmarkLayerID);
    addBookmarksToMap();
};

export const addBookmarkFromMark = (): Either<boolean, boolean> => {
    const interaction = query('port/map/interaction');
    if (interaction.label === 'mark') {
        const pos = interaction.state.coordinates;
        addBookmark(pos);
        return right(true);
    }
    return left(false);
};

export const addBookmark = (pos: Coordinate) => {
    dispatch('data/layers', state => {
        const fc =
            bookmarkLayerID in state
                ? state[bookmarkLayerID]
                : defaultBookmarks();
        const n = fc.features.length + 1;
        const id = pos.length > 0 ? `ID_${n}_${pos[0]}` : `ID_${n}`;
        const name = `${tr.view('bookmark')} ${n}`;
        fc.features.push({
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: pos,
            },
            properties: { name, color: 'black' },
            id,
        });
        return { ...state, [bookmarkLayerID]: fc };
    });
    updateBookmarks();
};

export const removeBookmark = (name: string) => {
    dispatch('data/layers', state => {
        const fc =
            bookmarkLayerID in state
                ? state[bookmarkLayerID]
                : defaultBookmarks();
        const features = fc.features.filter(
            f => getFeatureProp(f, 'name', '') !== name
        );
        const newFc = { ...fc, features };
        return { ...state, [bookmarkLayerID]: newFc };
    });
    updateBookmarks();
};

export const addBookmarksToMap = () => {
    dispatch('data/datasetMetadata', state => ({
        ...state,
        [bookmarkMetadataID]: bookmarkMetadata,
    }));
    dispatch('data/maps', mapsRemote =>
        mapRemote(mapsRemote, maps =>
            maps.map(m => ({
                ...m,
                layers: m.layers
                    .filter(l => l !== bookmarkLayerID)
                    .concat([bookmarkLayerInfo().id]),
            }))
        )
    );
    addLayerData(bookmarkLayerInfo());
    addLayer(
        mapName,
        () =>
            some({
                name: bookmarkLayerName,
                info: bookmarkLayerInfo(),
                metadata: bookmarkMetadata,
            }),
        () => right(some(getBookmarks()))
    );
};

export const setBookmarkIndex = (i: number) =>
    assign('component/bookmark/current-index', i);

export const resetBookmarkIndex = () =>
    assign('component/bookmark/current-index', null);

export const setBookmarkName = (name: string) => {
    getBookmarkIndex().map(bix =>
        dispatch('data/layers', layers => {
            const bml = layers[bookmarkLayerID];
            if (bml !== undefined) {
                const f = bml.features[bix];
                if (f !== undefined) {
                    f.properties = { ...f.properties, name };
                }
            }
            return layers;
        })
    );

    updateBookmarks();
};

export const setBookmarkColor = (color: string) => {
    getBookmarkIndex().map(bix =>
        dispatch('data/layers', layers => {
            fromNullable(layers[bookmarkLayerID])
                .chain(bml => index(bix, bml.features))
                .map(f => {
                    f.properties = { ...f.properties, color };
                });
            return layers;
        })
    );
    updateBookmarks();
};

logger('loaded');
