import * as debug from 'debug';
import { getUserId } from 'sdi/app';
import { icon } from 'sdi/components/button';
import { DIV, INPUT, H1, H2 } from 'sdi/components/elements';
import { renderCheckbox } from 'sdi/components/input';
import tr, { formatNumber, fromRecord, Translated } from 'sdi/locale';
import { markdown } from 'sdi/ports/marked';
import { Category } from 'sdi/source';
import { notEmpty } from 'sdi/util';
import {
    getMapsInCategory,
    getMode,
    getSelectedCategories,
    getVisibleCategories,
} from 'view/src/queries/mapnavigator';
import {
    filterMapsWithPat,
    toggleHomeMode,
    removeFilterMapsCat,
    addFilterMapsCat,
} from '../../events/mapnavigator';
import { renderSwitchToTableButton, renderSwitchToTileButton } from '../button';

const logger = debug('sdi:sidebar');

const searchInput = () =>
    INPUT({
        key: 'map-navigator-search-input',
        type: 'search',
        name: 'search',
        placeholder: tr.view('searchAtlas'),
        onChange: e => filterMapsWithPat(e.currentTarget.value.trim()),
    });

const searchButton = () => icon('search');

// const clearCategorySelection = makeLabelAndIcon('clear', 3, 'times', () =>
//     tr.core('unselect')
// );

const searchField = () => DIV('input-wrapper', searchInput(), searchButton());

const isSelectedCategory = (catId: string) =>
    getSelectedCategories().indexOf(catId) >= 0;

const isSelectedCatClass = (catId: string) =>
    isSelectedCategory(catId) ? 'selected' : '';

// const renderCategoryListItem = (cat: Category) =>
//     DIV(
//         {
//             key: `cat-item-${cat.id}`,
//             className: `list-item ${isSelectedCatClass(cat.id)}`,
//             onClick: () => filterMapsWithCat(cat.id),
//         },
//         icon('chevron-right'),
//         concat(
//             fromRecord(cat.name),
//             ' (',
//             formatNumber(getMapsInCategory(cat.id).length),
//             ')'
//         ),
//         isSelectedCategory(cat.id).map(() =>
//             clearCategorySelection(resetFilter)
//         )
//     );

// const renderCategoryList = (cats: Category[]) =>
// DIV(
// 'category__wrapper',
// H2('', tr.view('filterByCategory')),
// DIV('list', cats.map(renderCategoryListItem))
// );

const catCheckBox = (c: Category) =>
    renderCheckbox(
        'categories',
        () =>
            `${fromRecord(c.name)} (${formatNumber(
                getMapsInCategory(c.id).length
            )})` as Translated,
        b => (b ? addFilterMapsCat(c.id) : removeFilterMapsCat(c.id))
    );

const renderCategoryList = (cats: Category[] | readonly Category[]) =>
    cats.map(c => {
        return catCheckBox(c)(
            isSelectedCategory(c.id),
            isSelectedCatClass(c.id)
        );
    });

const renderSwitch = () =>
    DIV(
        'sidebar-switch',
        getUserId().map(() =>
            getMode() === 'tile'
                ? renderSwitchToTableButton(toggleHomeMode)
                : renderSwitchToTileButton(toggleHomeMode)
        )
    );

// const renderWebServices = (name: string) =>
//     getAppUrl(name).map(url =>
//         A(
//             {
//                 className: 'footerLink',
//                 target: '_blank',
//                 href: fromRecord(url.url),
//             },
//             fromRecord(url.label)
//         )
//     );

export const renderSidebar = () =>
    DIV(
        'sidebar',
        H1('pitch', markdown(tr.view('pitchAtlas'))),
        H2('pitch sub-pitch', markdown(tr.view('subPitchAtlas'))),
        searchField(),
        notEmpty(getVisibleCategories()).map(cats =>
            DIV(
                'category__wrapper',
                H2('', tr.view('filterByCategory')),
                renderCategoryList(cats)
            )
        ),
        renderSwitch(),
        // renderWebServices('data-access')
        DIV('sidebar-footer', markdown(tr.view('dataAccess')))
    );

export default renderSidebar;

logger('loaded');
