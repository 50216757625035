/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import Feature from 'ol/Feature';

import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import {
    PolygonStyleConfigSimple,
    PolygonStyleConfigContinuous,
    PolygonStyleConfigDiscrete,
    PolygonStyleConfig,
    PolygonInterval,
    PolygonDiscreteGroup,
} from '../../source';
import { StyleFn } from '.';
import { makePattern } from './pattern';
import { getLabelStyleFn } from './label';
import { findLow, withIndex } from './select';
import Geometry from 'ol/geom/Geometry';

// const makeStyle = (fill: Fill, stroke?: Stroke) => {
//     if (stroke && (stroke.getWidth() ?? 0) >= 0.5) {
//         return new Style({ fill, stroke });
//     }
//     return new Style({ fill });
// };

const makeStyles = ({
    strokeColor,
    fillColor,
    strokeWidth,
    pattern,
}: PolygonStyleConfigSimple | PolygonInterval | PolygonDiscreteGroup) => {
    const fill = new Fill({ color: fillColor });
    const stroke =
        strokeWidth > 0
            ? new Stroke({
                  color: strokeColor,
                  width: strokeWidth,
              })
            : undefined;

    if (pattern) {
        return [
            new Style({ fill }),
            new Style({
                stroke,
                fill: new Fill({
                    color: makePattern(
                        pattern.width,
                        pattern.angle,
                        pattern.color
                    ),
                }),
            }),
        ];
    }
    return [new Style({ fill, stroke })];
};

const polygonStyleSimple = (config: PolygonStyleConfigSimple) => {
    const labelStyle = getLabelStyleFn(config);
    const styles = makeStyles(config);

    return (feature: Feature<Geometry>, resolution: number) => {
        // const e = feature.getGeometry().getExtent();
        // const [w, h] = extent.getSize(e);
        // const maxRes = 2;
        // if (w / resolution < maxRes || h / resolution < maxRes) {
        //     return [];
        // }
        return labelStyle(feature, resolution, styles);
    };
};

type StyleReg = { [k: number]: Style[] };

const polygonStyleContinuous = (config: PolygonStyleConfigContinuous) => {
    const labelStyle = getLabelStyleFn(config);
    const intervals = config.intervals.filter(i => i.hidden != true);
    const styles = intervals.reduce<StyleReg>((acc, itv) => {
        // const [r, g, b] = itv.rgb;
        acc[itv.low] = makeStyles(itv);
        return acc;
    }, {});

    return (feature: Feature<Geometry>, resolution: number) => {
        const props = feature.getProperties();

        return findLow(props[config.propName], intervals).fold(
            labelStyle(feature, resolution, []),
            low => labelStyle(feature, resolution, styles[low])
        );
    };
};

const polygonStyleDiscrete = (config: PolygonStyleConfigDiscrete) => {
    const labelStyle = getLabelStyleFn(config);
    const groups = config.groups.filter(g => g.hidden != true);
    const groupStyles = groups.reduce<Style[][]>((acc, itv) => {
        // const [r, g, b] = itv.rgb;
        acc.push(makeStyles(itv));
        return acc;
    }, []);

    return (feature: Feature<Geometry>, resolution: number) => {
        const props = feature.getProperties();
        const value = props[config.propName];

        return withIndex(value, groups).fold(
            labelStyle(feature, resolution, []),
            idx => labelStyle(feature, resolution, groupStyles[idx])
        );
    };
};

const polygonStyle = (config: PolygonStyleConfig): StyleFn => {
    switch (config.kind) {
        case 'polygon-simple':
            return polygonStyleSimple(config);
        case 'polygon-continuous':
            return polygonStyleContinuous(config);
        case 'polygon-discrete':
            return polygonStyleDiscrete(config);
    }
};

export default polygonStyle;
