/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromPredicate } from 'fp-ts/lib/Either';
import { compose, identity } from 'fp-ts/lib/function';

import {
    DIV,
    IMG,
    P,
    SPAN,
    H2,
    H3,
    THEAD,
    TR,
    TH,
    TD,
    TABLE,
    TBODY,
    BUTTON,
    NodeOrOptional,
} from 'sdi/components/elements';
import tr, { formatDate, fromRecord, Translated } from 'sdi/locale';
import { AppPosterManifest, foldRemote, IMapInfo, ImgTuple } from 'sdi/source';
import { nameToString, tryNameToString } from 'sdi/components/button/names';

import { navigateMap } from '../../events/route';
import {
    findCategory,
    getHomeSortDirection,
    getMode,
    isSortedColumn,
} from '../../queries/mapnavigator';
import { notEmpty, uniqIdentified } from 'sdi/util';
import {
    getAllMapsRemoteFiltered,
    getPosters,
    userName,
} from '../../queries/app';
import { catOptions } from 'fp-ts/lib/Array';
import { renderDescription } from '../map-info';
import { markdown } from 'sdi/ports/marked';
import { buttonTooltipBottomRight } from 'sdi/components/tooltip';
import { setHomeTableSort } from 'view/src/events/mapnavigator';
import { HomeTableKey, HomeTableSort } from 'view/src/shape/types';
import { fromNullable } from 'fp-ts/lib/Option';
import { getUserId } from 'sdi/app';
import { mapStatus } from 'sdi/map';

const logger = debug('sdi:mapnavigator');

const mapTitle = (title: string) => H3('map-title', title);

const isGreaterThan100 = fromPredicate<string, string>(
    rec => rec.length >= 100,
    rec => rec
);

const trimDescription = (record: string) =>
    isGreaterThan100(record).fold(
        rec => rec,
        rec => `${rec.substr(0, 100)}...`
    );

const description = compose(
    (s: string) => P('word-wrap', s),
    trimDescription,
    fromRecord
);

const mapImage = (imageUrl: string) =>
    IMG({ loading: 'lazy', src: `${imageUrl}?size=small`, alt: '' });

const mapNoImage = () => DIV('no-image', SPAN('fa', nameToString('image')));

const imgWrapper = (map: IMapInfo) =>
    DIV('map-tile-img', fromNullable(map.imageUrl).foldL(mapNoImage, mapImage));

const renderStatusIcon = (map: IMapInfo) =>
    SPAN(
        'fa icon',
        map.status === 'draft' ? nameToString('lock') : '',
        map.status === 'published' ? nameToString('user-plus') : '',
        map.status === 'saved' ? nameToString('save') : ''
    );

const renderMap = (map: IMapInfo) => {
    const mid = map.id;
    const title = fromRecord(map.title);
    return BUTTON(
        {
            key: mid,
            className: 'map-tile',
            onClick: () => (mid ? navigateMap(mid) : null),
        },
        getUserId().map(() =>
            DIV(
                `map-status ${map.status}`,
                renderStatusIcon(map),
                SPAN(`status-label `, mapStatus(map))
            )
        ),
        imgWrapper(map),
        mapTitle(title),
        description(map.description)
        // readMore(title)
    );
};

const renderMaps = (render: (mapInfo: IMapInfo) => NodeOrOptional) =>
    foldRemote<IMapInfo[], string, NodeOrOptional[]>(
        () => [DIV('error', '~ Problem loading maps')],
        () => [
            DIV(
                'info',
                DIV(
                    'loader',
                    tr.core('loadingData'),
                    DIV({ className: 'loader-spinner' })
                )
            ),
        ],
        err => [DIV('error', `'~ Error loading maps' (${err})`)],
        maps => uniqIdentified(maps.map(identity)).map(render)
    )(getAllMapsRemoteFiltered());

// const renderMaps = () => uniqIdentified(getMaps().map(identity)).map(renderMap);
const renderTiles = () =>
    DIV(
        'content',
        getPosters().map(renderAppList),
        DIV(
            'map-list__wrapper',
            H2('', tr.view('mapListTitle')),
            DIV('map-list', ...renderMaps(renderMap))
        )
    );

const switchTableSort = (colName: HomeTableKey) => {
    const direction = getHomeSortDirection()
        .map(dir => (dir === 'asc' ? 'desc' : 'asc'))
        .getOrElse('asc');
    setHomeTableSort([colName, direction] as HomeTableSort);
};

const sortedClass = (colName: HomeTableKey) =>
    isSortedColumn(colName)
        ? getHomeSortDirection()
              .map(d => `sorted sorted-${d}`)
              .getOrElse('')
        : '';

const sortedIcon = (colName: HomeTableKey) =>
    isSortedColumn(colName)
        ? getHomeSortDirection()
              .map(d =>
                  d === 'asc'
                      ? nameToString('caret-up')
                      : nameToString('caret-down')
              )
              .getOrElse('')
        : '';

const titleWithSort = (colName: HomeTableKey) =>
    buttonTooltipBottomRight(
        `${tr.core('sort')} ${tr.view(colName)}` as Translated,
        {
            className: 'label-col',
            onClick: () => switchTableSort(colName),
        },
        SPAN(
            `${sortedClass(colName)}`,
            tr.view(colName),
            ' ',
            SPAN('fa', sortedIcon(colName))
        )
    );

const renderTableHeader = () =>
    THEAD(
        '',
        TR(
            '',
            TH('', titleWithSort('colTitle')),
            TH('', titleWithSort('colDescription')),
            TH('', titleWithSort('colCategory')),
            TH('', titleWithSort('colDate')),
            TH('', titleWithSort('colStatus')),
            TH('', titleWithSort('colAuthor'))
        )
    );

const renderRow = (map: IMapInfo) =>
    TR(
        { key: `row-${map.id}` },
        TD(
            { className: 'map-link', onClick: () => navigateMap(map.id) },
            fromRecord(map.title)
        ),
        TD('', renderDescription(fromRecord(map.description))),
        TD(
            '',
            catOptions(map.categories.map(findCategory))
                .map(({ name }) => fromRecord(name))
                .join(', ')
        ),
        TD('', formatDate(new Date(map.lastModified))),
        TD('', mapStatus(map)),
        fromNullable(map.user).chain(u => userName(u).map(name => TD('', name)))
    );

const renderTable = () =>
    DIV(
        'content',
        DIV(
            'map-table__wrapper',
            TABLE('', renderTableHeader(), TBODY('', ...renderMaps(renderRow)))
        )
    );

const imgSize = (image: ImgTuple) => image[0];
const imgUrl = (image: ImgTuple) => image[1];
const imgSrcSet = (images: ImgTuple[]) =>
    images.reduce((src, img) => {
        return src + `${imgUrl(img)} ${imgSize(img)},`;
    }, '');

const renderAppItem = ({
    icon,
    description,
    order,
    displayName,
    image,
    url,
}: AppPosterManifest) =>
    BUTTON(
        {
            key: `poster-${order}-${url}`,
            className: 'app-item',
            onClick: () => (location.href = url),
        },
        notEmpty(image).map(images =>
            DIV(
                'app-img',
                IMG({
                    srcSet: imgSrcSet(images.concat()),
                    sizes: '(max-width: 2000px) 290px, (max-width: 3000px) 390px, 590px',
                    src: `${imgUrl(images[0])}`,
                    alt: '',
                })
            )
        ),
        H3(
            'app-title',
            SPAN('fa picto', tryNameToString(icon)),
            fromRecord(displayName)
        ),
        DIV('app-description', markdown(fromRecord(description)))
    );

const renderAppList = (posters: AppPosterManifest[]) =>
    DIV(
        'app-list__wrapper',
        H2('', tr.view('applicationsListTitle')),
        DIV('list', posters.map(renderAppItem))
    );

export const render = () =>
    getMode() === 'tile' ? renderTiles() : renderTable();

export default render;

logger('loaded');
