/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import { DIV, SPAN, H2 } from 'sdi/components/elements';
import { mapRightTools, renderScaleline, storeOlMap } from 'sdi/map/controls';
import { create, IMapOptions, multiSelectOptions } from 'sdi/map';
import tr, { fromRecord } from 'sdi/locale';
import { MessageRecord } from 'sdi/source';

import {
    getCurrentBaseLayers,
    getMapInfo,
    getMapLayers,
    getCurrentSelection,
    getCurrentBaseLayerNames,
} from '../queries/app';
import { setMapBaseLayer, signalReadyMap } from '../events/app';
import {
    getInteraction,
    getInteractionMode,
    getLoading,
    getPrintRequest,
    getScaleLine,
    getView,
    HarvestLayerName,
} from '../queries/map';
import {
    dispatchView,
    endMark,
    measureEvents,
    scalelineEvents,
    selectFeatures,
    setExtractCollection,
    setInteraction,
    setPointerPosition,
    setPrintResponse,
    startMark,
    startTracker,
    stopPointerPosition,
    trackerEvents,
    updateLoading,
    viewEvents,
} from '../events/map';

// import geocoder from './legend-tools/geocoder';
import geocoder from 'sdi/components/geocoder';
import { addFeature } from '../events/harvest';
import { getGeometryType } from '../queries/harvest';
import { dispatchK } from 'sdi/shape';
import { fromNullable } from 'fp-ts/lib/Option';

const logger = debug('sdi:comp/map');
export const mapName = 'main-view';
const getMapLayerInfo = () =>
    fromNullable(getMapInfo()).fold([], m => getMapLayers(m));

const options: IMapOptions = {
    element: null,
    getBaseLayer: getCurrentBaseLayers,
    getMapInfo,
    getMapLayerInfo,
    getView,

    updateView: viewEvents.updateMapView,
    setScaleLine: scalelineEvents.setScaleLine,
    setLoading: updateLoading,
};

let mapSetTarget: (t: Element | null) => void;
let mapUpdate: () => void;

const attachMap = () => (element: HTMLElement | null) => {
    // logger(`attachMap ${typeof element}`);
    if (!mapUpdate) {
        const {
            update,
            setTarget,
            selectable,
            measurable,
            trackable,
            extractable,
            markable,
            printable,
            positionable,
            editable,
            andThen,
        } = create(mapName, { ...options, element });
        mapSetTarget = setTarget;
        mapUpdate = update;
        signalReadyMap();

        selectable(
            multiSelectOptions({
                selectFeatures,
                getSelected: getCurrentSelection,
            }),
            // singleSelectOptions({
            //     filter: (_f, layer) => layer.get('id') !== HarvestLayerName,
            //     selectFeature,
            //     clearSelection,
            //     getSelected,
            // }),
            getInteraction
        );

        measurable(
            {
                updateMeasureCoordinates:
                    measureEvents.updateMeasureCoordinates,
                stopMeasuring: measureEvents.stopMeasure,
            },
            getInteraction
        );

        trackable(
            {
                resetTrack: trackerEvents.resetTrack,
                setCenter: center =>
                    viewEvents.updateMapView({ dirty: 'geo', center }),
                updateTrack: trackerEvents.updateTrack,
            },
            getInteraction
        );

        extractable(
            {
                setCollection: setExtractCollection,
            },
            getInteraction
        );

        markable({ startMark, endMark }, getInteraction);

        // highlightable(getSelected); // TODO

        printable(
            {
                getRequest: getPrintRequest,
                setResponse: setPrintResponse,
            },
            getInteraction
        );

        positionable(
            {
                setPosition: setPointerPosition,
                stopPosition: stopPointerPosition,
            },
            getInteraction
        );

        editable(
            {
                addFeature,
                setGeometry: () => void 0,
                getCurrentLayerId: () => HarvestLayerName,
                getGeometryType: () => getGeometryType().getOrElse('Polygon'),
            },
            getInteraction
        );

        andThen(storeOlMap);
    }
    if (element) {
        mapSetTarget(element);
    } else {
        mapSetTarget(null);
    }
};

const renderLoading = (ms: Readonly<MessageRecord[]>) =>
    DIV(
        {
            className: `loading-layer-wrapper ${
                ms.length === 0 ? 'hidden' : ''
            }`,
        },
        H2({}, tr.view('loadingData')),
        ms.map(r =>
            DIV(
                {
                    className: 'loading-layer',
                    key: fromRecord(r),
                },
                SPAN({ className: 'loader-spinner' }),
                fromRecord(r)
            )
        )
    );

const render = () => {
    if (mapUpdate) {
        mapUpdate();
    }

    // FIXME - this is a hell of a hack - pm
    const withPrintSize = (() => {
        const i = getInteraction();
        const req = getPrintRequest();
        if (i.label === 'print' && req !== null) {
            return {
                style: {
                    width: Math.round((req.width * req.resolution) / 25.4),
                    height: Math.round((req.height * req.resolution) / 25.4),
                },
            };
        }
        return {};
    })();

    return DIV(
        {
            className: `map-wrapper map-interaction-${getInteractionMode()}`,
            'aria-label': `${tr.core('map')}`,
        },

        DIV({
            key: '__this_is_a_unique_map__',
            className: 'map',
            tabIndex: 0,
            ref: attachMap(),
            ...withPrintSize,
        }),
        renderLoading(getLoading()),
        geocoder(dispatchView, setInteraction),
        mapRightTools(dispatchK('port/map/view'), getView, startTracker, {
            setBaseLayer: setMapBaseLayer,
            getBaseLayer: getCurrentBaseLayerNames,
        }),
        // baseSwitch(),
        renderScaleline(getScaleLine())
    );
};

export default render;

logger('loaded');
