/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import {
    DIV,
    A,
    NodeOrOptional,
    HEADER,
    SPAN,
    DETAILS,
    SUMMARY,
    BUTTON,
} from './elements';
import langSwitch from './lang-switch';
import {
    getUserId,
    getRoot,
    getLang,
    getAppManifest,
    getRelativeRoot,
    tryLogout,
    getUserData,
    getAppUrl,
    getMaintenanceEvent,
} from '../app';
import { tr, fromRecord } from '../locale';
import { getPathElements } from '../util';
import { renderAppSelect } from './app-select';
import { appDisplayName, appUrl, IUser } from '../source';
import { Option } from 'fp-ts/lib/Option';
// import { makeLabelAndIcon } from './button';
import { icon, makeLabelAndIcon } from './button';

const logger = debug('sdi:header');

export const loginURL = () => {
    const path = getPathElements(document.location.pathname);
    const root = getPathElements(getRelativeRoot());
    const next = path
        .filter((p, i) => (i < root.length ? p !== root[i] : true))
        .join('/');
    return `${getRoot()}login/${next}`;
};

const documentationLink = () =>
    DIV(
        'documentation',
        DIV(
            'help',
            icon('life-ring'),
            A(
                {
                    href: `https://cartostation.com/documentation/${getLang()}/index`,
                    target: '_blank',
                },
                tr.core('help')
            )
        ),
        getAppUrl('contact').map(url =>
            DIV(
                'contact',
                icon('envelope'),
                A(
                    { className: '', href: fromRecord(url.url) },
                    // fromRecord(url.label)
                    tr.core('contact')
                )
            )
        )
    );

const makeAppName = (appCodename: string) =>
    getAppManifest(appCodename)
        .chain(appDisplayName)
        .map<string>(n => fromRecord(n))
        .getOrElse(appCodename);

const renderTitle = (appCodename: string, navigateHome?: () => void) =>
    getAppManifest(appCodename).map(manifest => {
        const appName = appDisplayName(manifest)
            .map<string>(n => fromRecord(n))
            .getOrElse(appCodename);

        return navigateHome === undefined
            ? A(
                  {
                      href: appUrl(manifest),
                  },
                  appName
              )
            : SPAN({ onClick: navigateHome }, appName);
    });

export const username = (user: IUser) => {
    const ut = user.name.trim();
    if (ut.length === 0) {
        return `User ${user.id}`;
    }
    return ut;
};

export const logoutButton = makeLabelAndIcon('logout', 3, 'sign-out-alt', () =>
    tr.core('logout')
);

const logout = () =>
    BUTTON(
        {
            className: 'logout',
            onClick: tryLogout,
        },
        icon('sign-out-alt'),
        SPAN('label', tr.core('logout'))
    );

const withUser = (appCodename: string) => (user: IUser) =>
    DIV(
        'user',
        DETAILS(
            'user-details',
            SUMMARY(
                '',
                SPAN(
                    'menu',
                    icon('user'),
                    SPAN('menu-label', username(user)),
                    icon('caret-down')
                )
            ),
            DIV(
                'details-tail',
                renderAppSelect(appCodename),
                documentationLink(),
                DIV('user-details__wrapper', logout())
            )
        )
    );

const withoutUser = (appCodename: string) =>
    DIV(
        'user',

        DETAILS(
            'user-details',
            SUMMARY(
                '',
                SPAN(
                    'menu',
                    icon('bars'),
                    SPAN('menu-label', tr.core('menu')),
                    icon('caret-down')
                )
            ),
            DIV(
                'details-tail',
                renderAppSelect(appCodename),
                documentationLink()
            )
        ),
        DIV(
            'navigate login',
            icon('sign-in-alt'),
            A({ href: loginURL() }, tr.core('login'))
        )
    );

// const withoutUser = () =>
//     DIV(
//         'navigate login',
//         icon('sign-in-alt'),
//         A({ href: loginURL() }, tr.core('login'))
//     );

const renderUser = (appCodename: string, user: Option<IUser>) =>
    user.map(withUser(appCodename)).getOrElse(withoutUser(appCodename));

const authClass = () =>
    getUserId().foldL(
        () => 'not-authenticated',
        () => 'authenticated'
    );

const renderTools = (appCodename: string) =>
    DIV('tools', langSwitch(appCodename));

const hasMaintenanceBanner = () =>
    getMaintenanceEvent().isSome() ? 'with-banner' : '';

type HeaderOptions = {
    appElement: NodeOrOptional;
    navigateHome: () => void;
};

const header = (appCodename: string, options = {} as Partial<HeaderOptions>) =>
    HEADER(
        `header ${authClass()} ${hasMaintenanceBanner()}`,
        A(
            {
                className: 'logo-link',
                href: getRoot(),
                'aria-label': makeAppName('dashboard'),
            },
            DIV('brand-logo', DIV('brand-name'))
        ),
        DIV('app-title', renderTitle(appCodename, options.navigateHome)),
        options.appElement,
        DIV(
            'header-toolbar',
            renderUser(appCodename, getUserData()),
            renderTools(appCodename)
        )
    );

export default header;

logger('loaded');
