/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { Option, none, some, fromNullable, fromEither } from 'fp-ts/lib/Option';

import { makeTable2Manip } from 'sdi/components/table2/layer';

import {
    FeatureCollection,
    Inspire,
    StreamingState,
    ILayerInfo,
    withQueryString,
    withoutWidget,
    ExportConfig,
    ExportConfigIO,
} from 'sdi/source';
import { queryK, query, dispatchK } from 'sdi/shape';
import { base64Decode, base64Encode, isNotNullNorUndefined } from 'sdi/util';
import { getLang } from 'sdi/app/queries';
import { getLayerPropertiesKeys } from 'sdi/util';

import { updateLayer } from '../events/table';
import { getGeometry } from '../queries/harvest';
import { getLayerDataFromUri } from '../queries/app';
import {
    getCurrentLayerInfo,
    getLayerData,
    getSyntheticLayerInfoOption,
} from './app';

const logger = debug('sdi:queries/table');

// Layer / FeatureCollection

const getConfigFields = (info: ILayerInfo) =>
    fromNullable(info.featureViewOptions).chain(options =>
        options.type === 'config'
            ? some(
                  options.rows
                      .filter(withoutWidget)
                      .filter(({ lang }) => lang === getLang())
                      .map(({ propName }) => propName)
              )
            : none
    );

const getFields = (info: ILayerInfo, metadata: Inspire) =>
    getConfigFields(info).getOrElseL(() =>
        getLayerDataFromUri(metadata.uniqueResourceIdentifier)
            .map(getLayerPropertiesKeys)
            .getOrElse([])
    );

export const encodeConfig = (exportConfig: ExportConfig) =>
    encodeURIComponent(base64Encode(JSON.stringify(exportConfig)));

export const decodeConfig = (s: string) => {
    try {
        const ds = base64Decode(decodeURIComponent(s));
        const d = JSON.parse(ds);
        return fromEither(ExportConfigIO.decode(d));
    } catch (_e) {
        return none;
    }
};

/**
 * this thing is a temporary hack, because angled does not implement a streaming data source
 * but they require to have filters applied on excel exports.
 */
const getFilters_ = (metadata: Inspire) => {
    const isAngled =
        metadata.resourceIdentifier?.split('://', 2)[0] === 'angled';
    if (isAngled) {
        return tableQuery().filters.map(filter => ({
            ...filter,
            columnName: '_none_',
        }));
    }
    return getStreamInfo().filters;
};

export const getExportLink =
    (info: ILayerInfo, metadata: Inspire) => (form: 'csv' | 'xlsx') => {
        const filters = getFilters_(metadata);
        if (filters.length < 1) {
            // keep the original way to do - to show fields in the url
            return withQueryString(metadata.uniqueResourceIdentifier, {
                form,
                lang: getLang(),
                field: getFields(info, metadata),
            });
        } else {
            // or make a base-64 based url to include filters
            return withQueryString(metadata.uniqueResourceIdentifier, {
                form,
                lang: getLang(),
                q: encodeConfig({ fields: getFields(info, metadata), filters }),
            });
        }
    };

export const getLayer = (): Option<FeatureCollection> =>
    getCurrentLayerInfo().chain(({ metadata }) =>
        getLayerData(metadata.uniqueResourceIdentifier).getOrElse(none)
    );

export const getLayerOption = () => getLayer();

// export const hashWindow = (
//     w: TableWindow,
//     s: Option<StreamingRequestSort>,
//     f: StreamingRequestFilter[]
// ) => {
//     let hash = `${w.offset}-${w.size}`;

//     s.map(s => {
//         hash += `-${s.column}/${s.direction}`;
//     });
//     f.forEach(filter => (hash += `-${hashRequestFilter(filter)}`));

//     return hash;
// };

export const getStreamingUrl = (metadata: Inspire) =>
    isNotNullNorUndefined(metadata.dataStreamUrl)
        ? some(metadata.dataStreamUrl)
        : none;

export const getStream: () => StreamingState = () =>
    query('data/features/stream');

export const tableQuery = queryK('component/table');

export type StreamLoadingStatus = 'no-stream' | 'loading' | 'loaded' | 'error';

export const {
    getFeatureData,
    getLayerSource,
    streamLoadingStatus,
    getStreamInfo,
} = makeTable2Manip(
    getCurrentLayerInfo,
    getSyntheticLayerInfoOption,
    getLayerData,
    getGeometry,
    tableQuery,
    queryK('data/features/stream'),
    () => none,
    dispatchK('data/features/stream'),
    updateLayer,
    true
);

logger('loaded');
