import * as debug from 'debug';
import { getApiUrl } from 'sdi/app';
import { assign, dispatchK, dispatch } from 'sdi/shape';
import {
    GeometryObject,
    GeometryType,
    FeatureCollection,
    Feature,
} from 'sdi/source';
import { addLayer, defaultInteraction } from 'sdi/map';

import {
    addLayerData,
    loadMapLayerInfo,
    selectTableView,
    setCurrentLayer,
    setLayout,
    updateCurrentMapInPlace,
} from 'view/src/events/app';
import { postHarvestRequest } from 'view/src/remote';
import { getGeometry, findHarvest } from '../queries/harvest';
import { HarvestedLayer } from 'view/src/components/query';
import {
    getAllLinkedMapsIds,
    getLinks,
    HarvestMetadataID,
} from '../queries/map';
import {
    getCurrentLayer,
    getFirstLayer,
    getMapInfoOption,
    getMapLayers,
} from '../queries/app';
import { loadMetadata } from './app';
import { MiniStep } from 'sdi/map/mini';
import { AppLayout } from '../shape/types';
import { withoutBookmarksOrHarvest } from '../queries/map';
import {
    harvestLayerInfo,
    harvestMetadata,
    removeHarvestLayer,
    resetInteraction,
} from './map';
import { clearCurrentStreamData } from './table';
import { fromNullable } from 'fp-ts/lib/Option';
import { mapName } from '../components/map';
import { noop } from 'sdi/util';

const logger = debug('sdi:harvest');

const harvest = dispatchK('component/harvest/results');

export const addFeature = (f: Feature) => {
    setGeometry(f.geometry);
    prepareGeometryForHarvesting();
};

export const prepareGeometryForHarvesting = () =>
    getGeometry().map(() => {
        const linkedList = getLinks('backward').getOrElse([]);
        getLinks('forward').map(flist =>
            linkedList
                .concat(flist)
                .map(m =>
                    getMapLayers(m).map(layer => loadMetadata(layer.metadataId))
                )
        );
        // setLayout(AppLayout.Query);

        removeHarvestLayer();
        // addHarvestLayer();
        addHarvestToMap();
        clearCurrentStreamData();
        fromNullable(getCurrentLayer()).fold(
            getFirstLayer().fold(noop(), info => setCurrentLayer(info.id)),
            id => setCurrentLayer(id)
        );
        endDrawing();
        selectTableView();
    });

export const request = (
    mapId: string,
    metadata: string,
    id: number,
    groupId: number
) =>
    getGeometry().map(geometry => {
        const h = findHarvest(mapId, metadata).getOrElse({
            tag: 'initial',
            metadataId: metadata,
            mapId,
            id,
            groupId,
        });

        if ('initial' === h.tag) {
            const start = harvestLoading(mapId, metadata, id, groupId);
            postHarvestRequest(getApiUrl('harvest/'), { metadata, geometry })
                .then(results =>
                    harvestLoaded(mapId, metadata, results, start, id, groupId)
                )
                .then(() => getAllLinkedMapsIds(mapId).map(loadMapLayerInfo))
                .catch(err =>
                    harvestError(mapId, metadata, `${err}`, start, id, groupId)
                );
        }
    });

const crsLambert72 = {
    type: 'name',
    properties: {
        name: 'epsg:31370',
    },
};

/**
 * Add CRS to geojson geometry
 *
 * As of https://tools.ietf.org/html/rfc7946#section-4
 * GeoJSON does not support CRS annotation anymore, nevertheless GEOS still
 * supports it so it's the easiest way to have 31370 all the way.
 *
 * @param geom
 */
const withCRS = (geom: GeometryObject) =>
    Object.assign({}, geom, { crs: crsLambert72 });

export const setGeometry = (g: GeometryObject) =>
    assign('component/harvest/geometry', withCRS(g));

export const clearGeometry = () => assign('component/harvest/geometry', null);

export const setGeometryType = (t: GeometryType) =>
    assign('component/harvest/geometry-type', t);

export const clearGeometryType = () =>
    assign('component/harvest/geometry-type', null);

export const startDrawing = (geometryType: GeometryType) => {
    clearGeometry();
    assign('component/harvest/geometry-type', geometryType);
    assign('port/map/interaction', {
        label: 'create',
        state: { geometryType },
    });
};

export const endDrawing = () => {
    assign('port/map/interaction', defaultInteraction());

    //harvest visible layers (of current map)
    getMapInfoOption().map(info =>
        withoutBookmarksOrHarvest(getMapLayers(info))
            .reverse()
            .map((l, id) => {
                if (l.visible) request(info.id, l.metadataId, id, 0);
            })
    );
};

export const removeHarvest = (mid: string) =>
    harvest(hs => hs.filter(h => h.metadataId !== mid));

const filterHarvest = (mid: string) => (h: HarvestedLayer) =>
    h.metadataId !== mid;

// export const harvestInitial = (mapId: string, metadataId: string) => {
//     const f = filterHarvest(metadataId);
//     harvest(hs => hs.filter(f).concat({ tag: 'initial', metadataId, mapId }));
// };

export const harvestLoading = (
    mapId: string,
    metadataId: string,
    id: number,
    groupId: number
) => {
    const f = filterHarvest(metadataId);
    const start = Date.now();
    harvest(hs =>
        hs
            .filter(f)
            .concat({ tag: 'loading', metadataId, start, mapId, id, groupId })
    );
    return start;
};

export const harvestError = (
    mapId: string,
    metadataId: string,
    error: string,
    start: number,
    id: number,
    groupId: number
) => {
    const f = filterHarvest(metadataId);
    const end = Date.now();
    harvest(hs =>
        hs.filter(f).concat({
            tag: 'error',
            metadataId,
            start,
            end,
            error,
            mapId,
            id,
            groupId,
        })
    );
};

export const harvestLoaded = (
    mapId: string,
    metadataId: string,
    data: FeatureCollection,
    start: number,
    id: number,
    groupId: number
) => {
    const f = filterHarvest(metadataId);
    const end = Date.now();
    harvest(hs =>
        hs.filter(f).concat({
            tag: 'loaded',
            metadataId,
            start,
            end,
            data,
            mapId,
            id,
            groupId,
        })
    );
};

export const setMiniMap = (k: string, step: MiniStep) =>
    dispatch('component/harvest/minimap', mm => {
        mm[k] = step;
        return mm;
    });

export const clearHarvested = () => assign('component/harvest/results', []);

export const endHarvest = () => {
    clearHarvested();
    clearGeometry();
    clearGeometryType();
    resetInteraction();
    removeHarvestLayer();
    clearCurrentStreamData();
    fromNullable(getCurrentLayer()).map(setCurrentLayer);
};

export const printHarvest = () => {
    setLayout(AppLayout.QueryPrint);
    window.setTimeout(() => window.print(), 300);
    window.setTimeout(() => setLayout(AppLayout.MapAndInfo), 1000);
    // window.setTimeout(endHarvest, 1000);
};

export const addHarvestToMap = () => {
    harvestMetadata().map(meta =>
        dispatch('data/datasetMetadata', state => ({
            ...state,
            [HarvestMetadataID]: meta,
        }))
    );
    harvestLayerInfo().map(({ info, layer, data }) => {
        // logger(`${layer.id}`);
        updateCurrentMapInPlace(map => map.layers.push(layer.id));
        addLayerData(layer);
        addLayer(mapName, info, data);
    });
};

logger('loaded');
