/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import { DETAILS, DIV, H2, H3, LABEL, SUMMARY } from 'sdi/components/elements';
import tr, { fromRecord } from 'sdi/locale';
import {
    IBaseLayerGroup,
    IMapBaseLayer,
    IMapBaselayerOrGroup,
} from 'sdi/source';

import {
    getBaseLayersForService,
    getBaseLayerServices,
    getCurrentBaseLayerNamesInService,
    findBaseLayerGroup,
    getMapBaseLayers,
    getAllBaseLayersInfo,
    getMapBaseLayerGroups,
    findBaseLayerFromId,
    getCurrentBaseLayersOrGroups,
    isCurrentGroup,
} from '../../queries/app';
import { setMapBaseLayerInService, addMapGroup } from '../../events/app';
import { fromPredicate } from 'fp-ts/lib/Option';
import { notEmpty } from 'sdi/util';
import { catOptions } from 'fp-ts/lib/Array';
import { icon } from 'sdi/components/button';

const logger = debug('sdi:webservices');

const renderBaseLayerOrGroup = (layerOrGroup: IMapBaselayerOrGroup) => {
    switch (layerOrGroup.tag) {
        case 'layer': {
            return findBaseLayerFromId(layerOrGroup.id).map(l =>
                LABEL('', fromRecord(l.name))
            );
        }
        case 'group': {
            return findBaseLayerGroup(layerOrGroup.id).map(g =>
                LABEL('', fromRecord(g.name))
            );
        }
    }
};
const renderSelectedBaseLayer = (layerOrGroup: IMapBaselayerOrGroup) =>
    DIV(
        'base-layer-selected',
        DIV('label-wrapper', renderBaseLayerOrGroup(layerOrGroup))
    );

const renderSelected = () =>
    DIV(
        'selected-base-layers',
        H3('', tr.view('selectedBaselayersTitle')),
        getCurrentBaseLayersOrGroups().map(renderSelectedBaseLayer)
    );

const renderBaseLayer = (
    add: (layer: string) => void,
    selected: boolean,
    baseLayer: IMapBaseLayer
) =>
    selected
        ? DIV(
              {
                  className: 'base-layer-item disabled',
              },
              icon('check'),
              LABEL('', fromRecord(baseLayer.name))
          )
        : DIV(
              {
                  className: 'base-layer-item',
                  onClick: () => add(baseLayer.codename),
              },
              icon('check'),
              LABEL('', fromRecord(baseLayer.name))
          );

const layerInSelection = (layerId: string) =>
    fromPredicate<string[]>(list => list.includes(layerId));

const renderLayerList = (
    service: string,
    selected: string[],
    mapBaseLayers: string[]
) =>
    getBaseLayersForService(service).map(bl => {
        const isIncludedOpt = layerInSelection(bl.id)(mapBaseLayers);
        return isIncludedOpt.map(() =>
            renderBaseLayer(
                setMapBaseLayerInService(service),
                selected.includes(bl.codename),
                bl
            )
        );
    });

const renderService = (service: string, mapBaseLayers: IMapBaseLayer[]) => {
    const listElements = renderLayerList(
        service,
        getCurrentBaseLayerNamesInService(service),
        mapBaseLayers.map(bl => bl.id)
    ).filter(e => e.isSome());
    return notEmpty(listElements).map(() =>
        DETAILS(
            { className: 'webservice', key: `renderService-${service}` },
            SUMMARY('', DIV({ className: 'webservice-name' }, service)),
            DIV('base-layer-list', ...listElements)
        )
    );
};

const renderServiceList = () =>
    getMapBaseLayers().foldL(
        () =>
            DIV(
                'list',
                ...getBaseLayerServices().map(service =>
                    renderService(service, getAllBaseLayersInfo())
                )
            ),
        layers =>
            DIV(
                'list',
                ...getBaseLayerServices().map(service =>
                    renderService(service, layers)
                )
            )
    );

const renderBaseLayerGroup = (
    add: (layer: string) => void,
    selected: boolean,
    group: IBaseLayerGroup
) =>
    selected
        ? DIV(
              {
                  className: 'base-layer-item disabled',
              },
              icon('check'),
              LABEL('', fromRecord(group.name))
          )
        : DIV(
              {
                  className: 'base-layer-item',
                  onClick: () => add(group.id),
              },
              icon('check'),
              LABEL('', fromRecord(group.name))
          );

const renderGroup = (layerOrGroup: IMapBaselayerOrGroup) =>
    findBaseLayerGroup(layerOrGroup.id).map(blg =>
        renderBaseLayerGroup(addMapGroup, isCurrentGroup(blg.id), blg)
    );
const renderGroups = () => {
    const groupsOpt = getMapBaseLayerGroups();
    const groupListOpt = groupsOpt.map(groups =>
        catOptions(groups.map(g => renderGroup(g)))
    );

    return groupListOpt.map(g =>
        notEmpty(g).map(groups =>
            DETAILS(
                { className: 'webservice' },
                SUMMARY(
                    '',
                    DIV(
                        { className: 'webservice-name' },
                        tr.view('baseLayerGroup')
                    )
                ),
                DIV('base-layer-list', ...groups)
            )
        )
    );
};

const webservices = () => {
    return DIV(
        'sidebar-main tool wms-picker',
        H2({}, tr.view('wmsSwitch')),
        DIV({}, tr.view('helptext:wmsSwitchTool')),
        DIV(
            'tool-body',
            renderSelected(),
            DIV(
                'available-base-layers',
                H3('', tr.view('availableBaselayersTitle')),
                renderServiceList(),
                renderGroups()
            )
        )
    );
};

export default webservices;

logger('loaded');
