/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { render } from 'react-dom';
import { Component, createElement } from 'react';

import { IShape } from '../shape';
import {
    IStoreInteractions,
    MessageRecord,
    ILayerInfo,
    Inspire,
} from '../source';
import { getLang, getParameterLang } from './queries';
import { fromNullable, none, some } from 'fp-ts/lib/Option';
import {
    loadFonts,
    setActivityToken,
    setApplicationTitle,
    setLang,
} from './events';
import React = require('react');
import { DIV } from '../components/elements';
import { Nullable } from '../util';
import { activity, visitAction, langAction } from '../activity';
import { closeDetailsOnEscape } from './details';

export * from './queries';
export * from './events';
export * from './rect';

const logger = debug('sdi:sdi/app');

export interface SyntheticLayerInfo {
    name: MessageRecord;
    info: ILayerInfo;
    metadata: Inspire;
}

export type RenderMain = () => React.ReactElement<unknown>;

export const stopApp = (titleText: string, err: string) => {
    const title = document.createElement('h1');
    const errorBlock = document.createElement('div');
    const link = document.createElement('a');
    const body = document.body;
    body.classList.add('emergency');
    while (body.firstChild) {
        body.removeChild(body.firstChild);
    }
    title.appendChild(document.createTextNode(titleText));
    err.split('\n').forEach(line => {
        const e = document.createElement('pre');
        e.appendChild(document.createTextNode(line));
        errorBlock.appendChild(e);
    });
    link.setAttribute('href', document.location.href);
    link.appendChild(document.createTextNode('Reload the application'));

    body.appendChild(title);
    body.appendChild(link);
    body.appendChild(errorBlock);
};

export const displayException = (err: string) =>
    stopApp('Sorry, Application Crashed', err);

class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        logger(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return DIV(
                { className: 'main error' },
                'Sorry, Application Crashed. Try reloading'
            );
        }

        return this.props.children;
    }
}

const { push, pop } = (() => {
    let stack: number[] = [];

    const push = (n: number) => stack.push(n);

    const pop = () => {
        if (stack.length > 0) {
            const n = stack[stack.length - 1];
            stack = [];
            return some(n);
        }
        return none;
    };
    return { push, pop };
})();

const OPTIMAL_FRAME_RATE = 16;
let FRAME_RATE = OPTIMAL_FRAME_RATE;

interface MainState {
    version: number;
    ts: number;
}

const defaultMainState = (): MainState => ({
    version: 0,
    ts: performance.now(),
});

const { registerUpdater, registerGetState, updateMain, getMainState } = (() => {
    let updater: ((v: number) => void) | null = null;
    let get: (() => MainState) | null = null;

    const updateMain = (v: number) => {
        if (updater !== null) {
            updater(v);
        }
    };

    const registerUpdater = (u: (v: number) => void) => {
        updater = u;
    };

    const registerGetState = (g: () => MainState) => {
        get = g;
    };

    const getMainState = () => {
        if (get !== null) {
            return get();
        }
        return defaultMainState();
    };

    return { updateMain, registerUpdater, getMainState, registerGetState };
})();

export const loop =
    (name: string, renderMain: RenderMain, effects?: () => void) =>
    (store: IStoreInteractions<IShape>) => {
        let interval: number | null = null;

        const frame = (animTimestamp: number) => {
            pop().map(newVersion => {
                const { version, ts } = getMainState();
                if (version < newVersion && animTimestamp - ts > FRAME_RATE) {
                    updateMain(newVersion);
                } else {
                    push(newVersion);
                }
            });

            interval = window.requestAnimationFrame(frame);
        };

        const focus = (lastFocusId: Nullable<string>) => {
            const focusId = store.get('app/focus-id');
            if (lastFocusId !== focusId) {
                return fromNullable(focusId).chain(id =>
                    fromNullable(document.getElementById(id)).map(e => {
                        e.focus();
                        return id;
                    })
                );
            }
            return none;
        };

        // eslint-disable-next-line @typescript-eslint/ban-types
        class Main extends Component<{}, MainState> {
            // private interval: number | null = null;
            // private rendering = false;
            private cached: React.ReactElement<any> | null = null;
            private lastFocusId: Nullable<string> = null;

            // eslint-disable-next-line @typescript-eslint/ban-types
            constructor(props: {}) {
                super(props);
                this.state = defaultMainState();
                registerGetState(() => this.state);
                registerUpdater((version: number) =>
                    // fixme You provided a `checked` prop to a form field without an `onChange` handler.
                    //  This will render a read-only field. If the field should be mutable use `defaultChecked`.
                    //  Otherwise, set either `onChange` or `readOnly`.
                    this.setState({ version, ts: performance.now() })
                );
            }

            componentDidMount() {
                interval = window.requestAnimationFrame(frame);
            }

            render() {
                // this.rendering = true;
                store.lock(() => {
                    this.cached = renderMain();
                });
                // this.rendering = false;
                return this.cached;
            }

            /**
             * Here we monitor the time spent updating
             * in order to adjust the framerate.
             */
            componentDidUpdate() {
                const ts = performance.now();
                const elapsed = Math.round(ts - this.state.ts);
                if (elapsed > FRAME_RATE) {
                    FRAME_RATE += 10;
                } else if (FRAME_RATE > OPTIMAL_FRAME_RATE) {
                    FRAME_RATE = Math.max(OPTIMAL_FRAME_RATE, FRAME_RATE - 10);
                }
                // logger(`Update(${this.state.version}) -> ${elapsed} / ${FRAME_RATE}`);

                focus(this.lastFocusId).map(id => (this.lastFocusId = id));
            }

            componentWillUnmount() {
                const itv = interval;
                if (itv !== null) {
                    window.cancelAnimationFrame(itv);
                    interval = null;
                }
            }
        }

        const root = document.createElement('div');
        root.setAttribute('class', `root ${name}`);
        document.body.appendChild(root);

        const mainElement = createElement(Main, {});
        const errorBoundary = createElement(ErrorBoundary, {}, mainElement);

        const start = () => {
            document.body.parentElement?.setAttribute('lang', getLang());
            setApplicationTitle(name);
            const activityLogger = activity(name);
            getParameterLang().map(setLang);
            setActivityToken().then(() => {
                activityLogger(visitAction());
                activityLogger(langAction(getLang()));
            });
            render(errorBoundary, root);
            store.observeVersion(push);
            loadFonts();
            if (effects) {
                effects();
            }
            closeDetailsOnEscape();
        };

        return start;
    };

logger('loaded');
