/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromNullable, fromPredicate, none } from 'fp-ts/lib/Option';
import { ReactNode } from 'react';

import {
    isAnchor,
    isContinuous,
    isDiscrete,
    StyleGroupType,
} from 'sdi/source/io';
import {
    DIV,
    SPAN,
    A,
    H1,
    H2,
    IMG,
    NAV,
    DETAILS,
    SUMMARY,
    NodeOrOptional,
} from 'sdi/components/elements';
import {
    getMessageRecord,
    LayerGroup,
    ILayerInfo,
    IMapInfo,
    FreeText,
} from 'sdi/source';
import tr, { concat, fromRecord } from 'sdi/locale';
import {
    datetimeBEFormated,
    flatten,
    nonEmptyStr,
    translateMapBaseLayer,
} from 'sdi/util';
import {
    buttonTooltipLeft,
    buttonTooltipTopRight,
    divTooltipTopRight,
} from 'sdi/components/tooltip';
import { getAppUrl, isUserMapPred, setFocusId } from 'sdi/app';
import { exportSelect } from 'sdi/components/export';

import queries, { getDomain, getPath } from '../../queries/legend';
import { setPage, setWMSLegendVisible } from '../../events/legend';
import {
    setLayerVisibility,
    setCurrentLayer,
    setLayout,
    selectTableView,
    saveStyle,
    clearSelectedFeatures,
} from '../../events/app';
import {
    getMapInfoOption,
    getDatasetMetadata,
    getLayerData,
    getCurrentBaseLayers,
    getCurrentName,
    getDatasetMetadataOption,
    getCurrentLayer,
    getMapLayers,
} from '../../queries/app';
import {
    legendRenderer,
    groupItems,
    OpacitySelector,
    renderOpacitySelector,
    getLayerItem,
} from 'sdi/components/legend/index';
import renderMapEditorial, { renderAttachments } from './../map-info';
import { AppLayout, LegendPage } from '../../shape/types';
import { ViewMessageKey } from '../../locale';
import webservices from '../legend-tools/webservices';
import print from '../legend-tools/print';
import share from '../legend-tools/share';
import location from '../legend-tools/location';
import measure from '../legend-tools/measure';
import harvest from '../legend-tools/harvest';
import { bookmarkLayerID } from 'view/src/queries/bookmark';
import { withoutBookmarksOrHarvest } from '../../queries/map';
import { setZoom, viewEvents } from '../../events/map';
import { getView } from '../../queries/map';
import { renderRelated } from '../related-maps';
import { getExportLink } from '../../queries/table';
import { mapStatus } from 'sdi/map';
import { markdown } from 'sdi/ports/marked';
import { dispatch } from 'sdi/shape';
import { icon } from 'sdi/components/button';

const logger = debug('sdi:legend');

interface Group {
    g: LayerGroup | null;
    layers: ILayerInfo[];
}

const opacitySelector: OpacitySelector = {
    isVisible: true, //when layer is configured for it, let show de opacity-input
    saveStyle,
};

const switchHidden = (item: StyleGroupType) =>
    fromNullable(item.hidden)
        .map(hidden => !hidden)
        .getOrElse(true);

const toggleItemVisibility = (layerInfo: ILayerInfo, itemIndex: number) => {
    dispatch('data/layerinfo-list', layers => {
        if (isDiscrete(layerInfo.style)) {
            const gps = layerInfo.style.groups;
            const currentVal = gps[itemIndex];
            gps[itemIndex] = {
                ...currentVal,
                hidden: switchHidden(currentVal),
            };
        } else if (isContinuous(layerInfo.style)) {
            const intervals = layerInfo.style.intervals;
            const currentVal = intervals[itemIndex];
            intervals[itemIndex] = {
                ...currentVal,
                hidden: switchHidden(currentVal),
            };
        }

        viewEvents.updateMapView({ dirty: 'style' });
        return layers;
    });
};
const displayWithCondition = (element: NodeOrOptional, predicate: boolean) =>
    predicate ? element : none;

const switchItemVisibility = (layerInfo: ILayerInfo, itemIndex: number) =>
    displayWithCondition(
        buttonTooltipTopRight(
            tr.view('visibility'),
            {
                onClick: () => {
                    toggleItemVisibility(layerInfo, itemIndex);
                },
            },
            getLayerItem(itemIndex, layerInfo).map(item =>
                icon(item.hidden ? 'eye-slash' : 'eye')
            )
        ),
        fromNullable(layerInfo.switchVisibility).getOrElse(false)
    );

// TODO: use this to add item opacity selector when all items don't have the same default opacity
// const allSameOpacity = (layerInfo: ILayerInfo) => {
//     const config = layerInfo.style;
//     if (isDiscrete(config) && isPolygonStyle(config)) {
//         return config.groups.every(g => {
//             return (
//                 getAlphaNb(g.fillColor)() ===
//                 getAlphaNb(config.groups[0].fillColor)()
//             );
//         });
//     }
//     return true;
// };

const renderItemActions = (layerInfo: ILayerInfo, itemIndex: number) =>
    DIV(
        'item-actions',
        switchItemVisibility(layerInfo, itemIndex)
        // displayWithCondition(
        //     renderOpacitySelector(layerInfo, opacitySelector, itemIndex),
        //     !allSameOpacity(layerInfo)
        // )
    );

const renderLayerActions = (layerInfo: ILayerInfo) =>
    DIV('layer-actions', renderOpacitySelector(layerInfo, opacitySelector));

const renderLegend = legendRenderer(
    id => fromNullable(getDatasetMetadata(id)),
    getView,
    setZoom,
    undefined,
    {
        layer: renderLayerActions,
        item: renderItemActions,
    }
);

type InfoRender = (info: ILayerInfo) => ReactNode;

/**
 * Returns a function branching on c and applying a on info if
 * true or b on info if false.
 */
const branchInfo =
    (a: InfoRender, b: InfoRender) => (c: boolean, info: ILayerInfo) => {
        if (c) {
            return a(info);
        }
        return b(info);
    };

const noExportBtn = () =>
    DETAILS(
        'download-table disabled',
        SUMMARY('btn btn-3 icon-only', icon('download')),
        DIV(
            'download__body',
            tr.view('helptext:noExport'),
            renderUrl('contact')
        )
    );

const renderExportLinks = (info: ILayerInfo) =>
    fromNullable(info.exportable).fold(noExportBtn(), exportable =>
        exportable
            ? getDatasetMetadataOption(info.metadataId).map(md =>
                  exportSelect(
                      getExportLink(info, md),
                      getCurrentName().getOrElse('')
                  )
              )
            : noExportBtn()
    );

const selectTableBtn = (info: ILayerInfo) =>
    buttonTooltipTopRight(
        tr.view('tooltip:dataAccess'),
        {
            onClick: () => {
                setCurrentLayer(info.id);
                selectTableView();
                setLayout(AppLayout.MapFS);
                setFocusId(`table-download-button-${info.id}`);
            },
        },
        icon('table')
    );

const switchVisibility = (info: ILayerInfo) =>
    buttonTooltipTopRight(
        tr.view('visible'),
        {
            onClick: () => {
                setLayerVisibility(info.id, !info.visible);
            },
        },

        icon(info.visible ? 'eye' : 'eye-slash')
    );

// todo rather use Array.some
const hasVisibleLayers = (group: Group) =>
    group.layers.map(l => l.visible).indexOf(true) >= 0;
const hasInvisibleLayers = (group: Group) =>
    group.layers.map(l => l.visible).indexOf(false) >= 0;

const switchVisibilityGroup = (group: Group) => {
    return buttonTooltipTopRight(
        tr.view('visible'),
        {
            onClick: () => {
                const allVisible = !hasInvisibleLayers(group);
                group.layers.map(info => {
                    setLayerVisibility(info.id, !allVisible);
                });
            },
        },
        // todo refactor: use nameToString to display button icons
        //  (also in other layer actions)
        SPAN({ className: hasVisibleLayers(group) ? 'visible' : 'not-visible' })
    );
};

const getFreeText = (ft: FreeText) => {
    if (isAnchor(ft)) {
        return fromRecord(ft.text);
    }

    return fromRecord(ft);
};

const renderMDInfo = (info: ILayerInfo) =>
    getDatasetMetadataOption(info.metadataId).map(m =>
        DIV(
            'layer-md-infos',
            fromNullable(m.resourceTitle).map(title =>
                DIV(
                    'kv layer-poc',
                    DIV('key label', tr.view('originalTitle')),
                    DIV('value', `${getFreeText(title)}`)
                    // `(${info.metadataId})`
                )
            ),
            fromNullable(m.resourceIdentifier).map(rid =>
                DIV(
                    'kv layer-name',
                    DIV('key label', tr.view('layerName')),
                    DIV('value', `${getDomain(rid)}/${getPath(rid)}`)
                )
            ),
            fromNullable(m.externalLink).chain(link =>
                nonEmptyStr(getFreeText(link)).map(l =>
                    DIV(
                        'kv layer-description',
                        DIV('key label', tr.view('link')),
                        DIV('value', markdown(l))
                    )
                )
            )
        )
    );

const renderDataInfo = (info: ILayerInfo) =>
    DETAILS(
        'download-table',
        SUMMARY('btn btn-3 icon-only', icon('info')),
        DIV(
            'download__body',
            renderMDInfo(info),
            fromNullable(info.group).map(
                g => `${tr.view('group')} ${fromRecord(g.name)}`
            )
        )
    );

const dataActions = branchInfo(
    (
        info: ILayerInfo // normal
    ) =>
        DIV(
            'layer-actions',
            switchVisibility(info),
            selectTableBtn(info),
            renderExportLinks(info),
            renderDataInfo(info)
        ),
    (
        info: ILayerInfo // bookmark
    ) =>
        DIV(
            'layer-actions bookmark',
            divTooltipTopRight(
                tr.view('visible'),
                {},
                SPAN({
                    className: info.visible ? 'visible' : 'hidden',
                    onClick: () => {
                        setLayerVisibility(info.id, !info.visible);
                    },
                })
            )
        )
);

const layerSelected = (info: ILayerInfo) =>
    getCurrentLayer() === info.id ? 'selected' : '';

const dataTitle = branchInfo(
    (
        info: ILayerInfo // normal
    ) => {
        const optMd = fromNullable(getDatasetMetadata(info.metadataId));
        const name =
            info.legend !== null && fromRecord(info.legend).length > 0
                ? fromRecord(info.legend)
                : optMd
                      .map(({ resourceTitle }) =>
                          fromRecord(getMessageRecord(resourceTitle))
                      )
                      .getOrElse(concat(info.id));
        return DIV(
            `layer-title`,
            optMd
                .map(md =>
                    getLayerData(md.uniqueResourceIdentifier).fold<ReactNode>(
                        err =>
                            SPAN(
                                {
                                    className: 'error',
                                    title: err,
                                },
                                name
                            ),
                        () => SPAN('', name)
                    )
                )
                .getOrElse(name)
        );
    },
    () =>
        // bookmark
        DIV('layer-title bookmark', tr.view('bookmarks'))
);

const renderUrl = (name: string) =>
    getAppUrl(name).map(url =>
        A(
            { className: 'contact-link', href: fromRecord(url.url) },
            fromRecord(url.label)
        )
    );

// const dataNoExportInfo = (info: ILayerInfo) =>
//     fromNullable(info.exportable).map(exportable =>
//         exportable
//             ? NODISPLAY()
//             : DIV(
//                   'helptext layer-info',
//                   tr.view('helptext:noExport'),
//                   ' ',
//                   renderUrl('contact')
//               )
//     );

const dataItem = (info: ILayerInfo) =>
    DIV(
        `layer-item ${layerSelected(info)}`,
        DIV(
            'layer-title__wrapper',
            dataTitle(info.id !== bookmarkLayerID, info),
            dataActions(info.id !== bookmarkLayerID, info)
        )
        // dataNoExportInfo(info)
    );

const dataItemSimple = (info: ILayerInfo) =>
    DIV(
        { className: `layer-item ${layerSelected(info)}` },
        DIV(
            'layer-title__wrapper',
            dataTitle(info.id !== bookmarkLayerID, info),
            selectTableBtn(info)
        )
    );

const renderData = (groups: Group[], withActions: boolean) =>
    groups.map(group => {
        const items = withActions
            ? group.layers.map(dataItem)
            : group.layers.map(dataItemSimple);
        const multipleLayersOpt = fromPredicate<ILayerInfo[]>(
            layers => layers.length > 1
        )(group.layers);
        if (group.g !== null) {
            return DIV(
                'legend-group named',
                DIV(
                    `legend-group-title`,
                    multipleLayersOpt.map(() => switchVisibilityGroup(group)),
                    SPAN('name', fromRecord(group.g.name))
                ),
                DIV('legend-group-items', ...items)
            );
        }
        return DIV('legend-group anonymous', ...items);
    });

const switchItem = (
    p: LegendPage,
    tk: ViewMessageKey,
    currentPage: LegendPage
) => {
    return buttonTooltipLeft(
        tr.view(tk),
        {
            className: `switch-item switch-${p} ${
                p === currentPage ? 'active' : ''
            }`,
            onClick: () => {
                clearSelectedFeatures();
                setLayout(AppLayout.MapFS);
                // unsetCurrentFeature();
                setPage(p);
            },
        },
        SPAN('picto')
    );
};

export const switcher = () => {
    const currentPage = queries.currentPage();
    return NAV(
        'switcher',
        switchItem('info', 'tooltip:info', currentPage),
        switchItem('data', 'tooltip:dataAndSearch', currentPage),
        switchItem('base-map', 'tooltip:base-map', currentPage),
        switchItem('print', 'tooltip:print', currentPage),
        switchItem('share', 'tooltip:ishare', currentPage),
        switchItem('measure', 'tooltip:measure', currentPage),
        switchItem('locate', 'tooltip:locate', currentPage),
        switchItem('spatial-filter', 'tooltip:spatial-filter', currentPage)
    );
};

const wmsLegend = () => {
    if (queries.displayWMSLegend()) {
        const legends = flatten(
            getCurrentBaseLayers().map(bl => {
                const tl = translateMapBaseLayer(bl);
                const lyrs = tl.params.LAYERS.split(',').reverse();
                return lyrs.map(lyr =>
                    DIV(
                        {
                            className: 'wms-legend-item',
                            key: `legend-image-${tl.url}-${lyr}`,
                        },
                        IMG({
                            src: `${tl.url}?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=${tl.params.VERSION}&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=${lyr}`,
                        })
                    )
                );
            })
        );

        return DIV(
            'wms-legend-wrapper',
            DIV(
                {
                    className: 'wms-legend-switch opened',
                    onClick: () => setWMSLegendVisible(false),
                },
                tr.view('wmsLegendHide')
            ),
            ...legends
        );
    }

    return DIV(
        'wms-legend-wrapper',
        DIV(
            {
                className: 'wms-legend-switch closed',
                onClick: () => setWMSLegendVisible(true),
            },
            tr.view('wmsLegendDisplay')
        )
    );
};

const wrapLegend = (...es: ReactNode[]) =>
    DIV('sidebar__wrapper sidebar-right ', ...es);

const mapVersion = (mapInfo: IMapInfo) =>
    DIV(
        'map-version',
        SPAN('version-value', tr.view(`${mapInfo.status}`)),
        mapInfo.status === 'published'
            ? DIV(
                  `map-status published`,
                  icon('user-plus'),
                  SPAN(`status-label `, mapStatus(mapInfo))
              )
            : '',
        SPAN(
            'version-date',
            `(${datetimeBEFormated(new Date(mapInfo.lastModified))})`
        )
    );

const renderMapInfoHeader = (mapInfo: IMapInfo, p: LegendPage) =>
    DIV(
        `sidebar-header legend-header-${p}`,
        isUserMapPred(mapInfo).map(() => mapVersion(mapInfo)),
        H1({}, fromRecord(mapInfo.title))
    );

const renderMapLegend = (mapInfo: IMapInfo) =>
    DIV(
        'map-legend__wrapper',
        H2({}, tr.view('mapLegend')),
        ...renderLegend(withoutBookmarksOrHarvest(getMapLayers(mapInfo))),
        wmsLegend()
    );

const renderMapInfo = (mapInfo: IMapInfo) =>
    wrapLegend(
        renderMapInfoHeader(mapInfo, 'info'),
        DIV(
            'sidebar-main styles-wrapper',
            renderMapEditorial(),
            renderMapLegend(mapInfo),
            renderRelated(),
            renderAttachments(mapInfo)
        )
    );

const helptextMapData = (withActions: boolean) =>
    withActions
        ? tr.view('helptext:mapdataTool')
        : tr.view('helptext:mapdataToolSimple');

export const renderMapData = (mapInfo: IMapInfo, withActions = true) =>
    DIV(
        { className: 'sidebar-main datas-wrapper' },
        H2({}, tr.view('mapData')),
        DIV({}, helptextMapData(withActions)),
        ...renderData(
            groupItems(withoutBookmarksOrHarvest(getMapLayers(mapInfo))),
            withActions
        )
        // harvest()
    );

const legend = () => {
    const currentPage = queries.currentPage();
    return getMapInfoOption().map(mapInfo => {
        switch (currentPage) {
            case 'base-map':
                return wrapLegend(
                    renderMapInfoHeader(mapInfo, 'base-map'),
                    webservices()
                );
            case 'data':
                return wrapLegend(
                    renderMapInfoHeader(mapInfo, 'data'),
                    renderMapData(mapInfo)
                );
            case 'info':
                return renderMapInfo(mapInfo);
            case 'locate':
            case 'locate-bookmark':
                return wrapLegend(
                    renderMapInfoHeader(mapInfo, 'locate'),
                    location(currentPage)
                );
            case 'measure':
                return wrapLegend(
                    renderMapInfoHeader(mapInfo, 'measure'),
                    measure()
                );
            case 'print':
                return wrapLegend(
                    renderMapInfoHeader(mapInfo, 'print'),
                    print()
                );
            case 'share':
                return wrapLegend(
                    renderMapInfoHeader(mapInfo, 'share'),
                    share()
                );
            case 'spatial-filter':
                return wrapLegend(
                    renderMapInfoHeader(mapInfo, 'spatial-filter'),
                    harvest(mapInfo)
                );
        }
    });
};

export default legend;

logger('loaded');
