/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { DIV, SPAN, H2, H3 } from 'sdi/components/elements';
import tr from 'sdi/locale';

import { setLayout } from '../events/app';
import { measureEvents } from '../events/map';
import { measureQueries } from '../queries/map';
import { AppLayout } from '../shape/types';
import { makeLabelAndIcon } from './button';
import { some } from 'fp-ts/lib/Option';

const stopMeasure = () => {
    setLayout(AppLayout.MapFS);
    measureEvents.stopMeasure();
};

const measure = () => SPAN('measure', measureQueries.getMeasured());

const btnStop = makeLabelAndIcon(
    'close',
    1,
    'stop',
    () => tr.view('stop'),
    some(`stop-measure`)
);

const render = () => {
    return DIV(
        'tool-widget geo-measure',
        H2({}, tr.view('measureTool')),
        DIV({}, tr.view('helptext:measureHelp')),
        H3({}, tr.view('total'), ' : ', measure()),
        btnStop(stopMeasure)
    );
};

export default render;
